import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment.prod';
import { Categoriain } from '../../interface/categoria.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  dataCategoira:Categoriain[]= [];

  constructor( private http: HttpClient ) { }

  categoriagetFilterData(orderBy:string, equalTo:string){

		return this.http.get(`${environment.Api}categorias.json?orderBy="${orderBy}"&equalTo="${equalTo}"&print=pretty`)
                      .pipe(

                        map( (resp:any)=>{

                          this.dataCategoira = Object.keys(resp).map( (a:any)=>({

                            icon: resp[a].icon,
                            imagen: resp[a].imagen == '' || resp[a].imagen == 'assets/img/categories/default/default-horizontal-slider.jpg' ? '' : `${ environment.Server}assets/img/categories/${resp[a].tienda}/${resp[a].imagen}`,
                            imagenprincipal: resp[a].imagenprincipal == undefined || resp[a].imagenprincipal == '' ? 'https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png' : `${ environment.Server}assets/img/categories/${resp[a].tienda}/${resp[a].imagenprincipal}`,
                            nombre: resp[a].nombre,
                            ordernar: resp[a].ordernar,
                            tienda:resp[a].tienda,
                            url: resp[a].url,
                  
                          }) as Categoriain )
                          .sort( (a,b):any=>{
                  
                            if( a.ordernar ){
                      
                              return ( Number(a.ordernar) - Number(b.ordernar ) );
                      
                            }
                          })

                          return resp;
                        })

                      )

  }

}
