import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from '../app-routing.module';
import { MainPageComponent } from './main-page/main-page.component';

import { SharedModule } from '../shared/shared.module';
import { ModalProductModule } from './main-page/modal-product/modal-product.module';
import { PipesModule } from '../pipes/pipes.module';
import { DirectiveModule } from '../directive/directive.module';

import { ProductosModule } from './main-page/productos/productos.module';

// import { Error404Component } from './main-page/error404/error404.component';
import { ModalOfertaModule } from './main-page/modal-oferta/modal-oferta.module';
import { ModalSucursalComponent } from './main-page/modal-sucursal/modal-sucursal.component';


@NgModule({
  declarations: [
    MainPageComponent,
    ModalSucursalComponent
    // Error404Component
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
    ModalProductModule,
    PipesModule,
    DirectiveModule,
    ProductosModule,
    ModalOfertaModule
  ]
})
export class PagesModule { }
