import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slices'
})
export class SlicesPipe implements PipeTransform {

  transform(value: object): any {

    // console.log( value )

    // const valor = value.toString().slice( 0,15 );

    if( value == undefined ){

      return '';

    }

  }

}
