import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';

declare var $:any;

@Injectable({
  providedIn: 'root'
})
export class CarritoService {

  dataCarrito:any[] = [];
  total:number= 0;

  totalItems:number = 0;

  animateCarrito:boolean = false;

  total$: BehaviorSubject<any> = new BehaviorSubject<any>(0)

  constructor( private router: Router,
              private cookie: CookieService) {  this.aggCarrito({ store: this.router.url.split('/')[1] }) }


  aggCarrito( data:any ){

      let exist = false;
  
      this.animateCarrito = true;
  
      if( localStorage.getItem(`carrito-${data.store}`) != undefined && data.store != undefined){
  
        this.dataCarrito = JSON.parse( localStorage.getItem(`carrito-${data.store}`)! );
  
        this.dataCarrito.forEach((element:any) => {
          
          if( data.url === element.url && data.detalles === element.detalles && data.unit != undefined ){
  
            element.unit = data.unit;
            element.newPrecio = data.unit*element.precio[1];
  
            exist = true;
  
            return;
  
          }
  
        });
  
        if( !exist && data.unit != undefined  ){
  
          this.dataCarrito.push( data )
          
        }
  
        localStorage.setItem( `carrito-${data.store}`, JSON.stringify( this.dataCarrito ) )
  
        this.totalCurrencyCarrito(data);
  
      }else{
  
        if( this.dataCarrito.length != 0 && data.store != undefined ){
  
          this.dataCarrito.forEach( (elment:any)=>{
    
            if( data.url === elment.url && data.detalles === elment.detalles  && data.unit != undefined){
    
              elment.unit = data.unit;
              elment.newPrecio = data.unit*elment.precio[1];
    
              exist = true;
    
              return;
    
            }
    
          })
    
          if( !exist && data.unit != undefined){
    
            this.dataCarrito.push( data )
  
          }
          localStorage.setItem( `carrito-${data.store}`, JSON.stringify( this.dataCarrito ) )
    
        }else{
  
          if( data.unit != undefined ){
  
            this.dataCarrito.push( data )
  
            localStorage.setItem( `carrito-${data.store}`, JSON.stringify( this.dataCarrito ) )
  
          }
    
        }
  
        this.totalCurrencyCarrito(data)
  
      }
  
      setTimeout( ()=>{ this.animateCarrito = false;
      }, 800)
  
      setTimeout( ()=>{ localStorage.removeItem( `carrito-${data.store}` ) }, 21600000 )
      
  }
  
  //SE REMUEVE EL VALOR DEL CARRITO
  removeCaritot( data:any ){
  
      let indice:any;
  
      this.animateCarrito = true;
  
      this.dataCarrito = JSON.parse( localStorage.getItem(`carrito-${data.store}`)! );
      
      this.dataCarrito.forEach( (elment:any, index:any)=>{
  
        if( data.id == elment.id ){
  
          indice = index
  
          $(`.valorCarrito-${elment.id}`).val( 1 )
  
        }
        
      })
  
      this.dataCarrito.splice( indice , 1 );
  
      localStorage.setItem( `carrito-${data.store}`, JSON.stringify( this.dataCarrito ) )
  
      this.totalCurrencyCarrito(data);
  
      setTimeout( ()=>{ 
        this.animateCarrito = false;
        // this.dataCarrito.splice( indice , 1 );
      }, 800)
      
  }
  
  //TOTAL DEL CARRITO
    totalCurrencyCarrito(data:any){
  
      this.total = 0;
      this.totalItems = 0;
  
      if( localStorage.getItem(`carrito-${data.store}`) != undefined && data.store != undefined ){
  
        this.dataCarrito = JSON.parse( localStorage.getItem(`carrito-${data.store}`)! );
  
        this.dataCarrito.forEach( (valor:any)=>{
  
          this.total += valor.unit*valor.precio[1];
          this.totalItems += valor.unit;
    
        })
  
      }
  
      this.total$.next(this.total);
  
  }
  
  //BORRAR TODO LOS DATOS DEL CARRITO
    limpiarCarrito(store:any){
  
      this.animateCarrito = true;
  
      this.dataCarrito = JSON.parse( localStorage.getItem(`carrito-${store}`)! );
      
      this.dataCarrito.forEach( (elment:any, index:any)=>{
  
        $(`.valorCarrito-${elment.id}`).val( 1 )
        
      })
  
      this.dataCarrito = [];
  
      this.total = 0;
      this.totalItems = 0;
  
      localStorage.setItem( `carrito-${store}`, JSON.stringify( this.dataCarrito ) )
  
      setTimeout( ()=>{ this.animateCarrito = false;
  }, 800)
  
    this.total$.next(this.total);
  
  }

}
