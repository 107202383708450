<!-- MENU PARA EL CARRITO Y MONEDA -->
<div *ngIf="storeService.allDataStore[0].preload" class="menu-carrito fixed-bottom">

    <div class="container">

        <div class="row">

          <div class="col">

            <div class="text-menu-carrito">
                <!-- #offcanvasCambiomodeda -->
                <a data-bs-toggle="offcanvas" [href]="currency == '$' || storeService.allDataStore[0].monedaPrincipal == '$' ? '#' : '#offcanvasCambiomodeda'" role="button" aria-controls="offcanvasCambiomodeda" >

                    <p [ngStyle]="{'color': storeService.allDataStore[0].color}" style="font-weight: 500;"> {{ currency == '' ? storeService.allDataStore[0].monedaPrincipal : currency }} </p>

                </a>

            </div>

          </div>

          <div class="col text-center">

            <a data-bs-toggle="offcanvas" data-bs-target="#offcanvasCarrito" aria-controls="offcanvasCarrito">

                <div [ngStyle]="{'background': storeService.allDataStore[0].color}" class="carrito" [ngClass]="{'animate__animated animate__heartBeat': carritoService.animateCarrito}">

                    <div class="text-carrito">
                        <i class="fas fa-shopping-cart"></i>
                    </div>

                    <div *ngIf="carritoService.dataCarrito.length > 0" class="carrito-lleno">
                    </div>

                </div>

            </a>

          </div>

          <div class="col" style="padding: 0px;">

            <p class="precio-carrito" style="font-size: 10.8px" [ngStyle]="{'color': storeService.allDataStore[0].color}">{{ carritoService.total | currencyPrecio }}</p>
            
          </div>

        </div>

    </div>

</div>

<!-- MODAL PARA EL CARRITO  -->
<div class="offcanvas offcanvas-end" tabindex="-1" data-bs-scroll="true" data-bs-backdrop="false"  id="offcanvasCarrito"  aria-labelledby="offcanvasRightLabel" style="width: 540px;">

    <ng-container *ngIf="carritoofcanvas">
    
    <!-- head  -->
    <div class="offcanvas-header px-0 pt-0 pb-3 sombra">
  
      <div class="d-flex bd-highlight">
  
          <div class="p-0 bd-highlight flex-shrink-1"> 
  
              <button type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close" [ngStyle]="{'background': storeService.allDataStore[0].color}" style=" border-bottom-right-radius: 10px;padding:15px;border: 0;"> <i class="fas fa-arrow-left text-white"></i> </button>
  
          </div>
  
          <div class="p-2 w-100 bd-highlight" style="margin-top:5px;"> 
  
               <h5 id="offcanvasRightLabel"><i class="fas fa-shopping-cart"></i> TOTAL DE ITEMS <span>({{ carritoService.totalItems }})</span></h5>
  
          </div>
          
      </div>
  
    </div>
  
    <!-- BODY  -->
    <div class="offcanvas-body">
  
      <div class="container">
  
        <div *ngIf="carritoService.dataCarrito.length > 0" class="text-center" (click)="carritoService.limpiarCarrito( storeService.allDataStore[0].store )">

            <i class="far fa-trash-alt"></i> Vaciar Carrito

        </div>

        <div class="mb-4" ></div>

        <hr>

        <div *ngIf="carritoService.dataCarrito.length == 0" class="alert alert-warning" role="alert">

            <strong> 😥 No hay items en el carrito! </strong>

        </div>
     
        <div *ngFor="let item of carritoService.dataCarrito" class="row py-2 item-carrito">

            <div class="col-3 p-0" >
        
                <img [src]="item.image" class="img-carrito" loading="lazy" alt="...">
        
                <div class="oferta-carrito">
        
                    <span *ngIf="item.precio[0] != 'No disponible' && item.precio[0] != 'Nuevo' && item.precio[0] != 'Agotado' && item.precio[0] != undefined" style="background-color: #ff1010;" style="background-color: #ff1010; ">{{ item.precio[0] }}</span>
        
                    <span *ngIf="item.precio[0] == 'Nuevo' " style="background-color: #ffb700; ">{{ item.precio[0] }}</span> 
        
                </div>
        
            </div>
        
            <div class="col-8 p-0">
        
                <div class="d-flex flex-column bd-highlight">
        
                    <div class="bd-highlight titulo-producto mb-1">
                        <span class="m-0"> {{ item.name }} </span>
                    </div>
        
                    <div *ngFor="let item2 of item.detalles1" class="bd-highlight detalles-producto">
        
                        <span>
                            <label style="font-weight: 500;">  {{ item2.type }}: </label>
                            {{ item2.valor }} <br>
                        </span>
        
                    </div>
        
                    <div class="bd-highlight mt-1 item-preci mb-1">
        
                        <!-- SI NO VIENE OFERTA  -->
                        <span *ngIf="item.precio[1] != undefined && item.precio[2] == undefined" class="preci" [ngStyle]="{'color': storeService.allDataStore[0].color}"> {{ item.precio[1] | currencyPrecio }} </span> 
        
                        <!-- SI VIENE OFERTA  -->
                        <span *ngIf="item.precio[1] != undefined && item.precio[2] != undefined" class="preci" [ngStyle]="{'color': storeService.allDataStore[0].color}"> {{ item.precio[1] | currencyPrecio }} </span> &nbsp;
                        <span *ngIf="item.precio[1] != undefined && item.precio[2] != undefined" class="text-muted preci-ofert"> {{ item.precio[2] | currencyPrecio }} </span>
        
                        
                    </div>
        
                    <!-- <div > -->
        
                        <div class="input-group" style="width: 73% !important;">

                            <span *ngIf="item.unit > 1" (click)="aggCarrito( item, item.unit, 'minus' )" class="input-group-text" style="background:white; padding: 9px;" >
                                <i class="fas fa-minus"></i>
                            </span>
            
                            <span *ngIf="item.unit == 1" (click)="aggCarrito( item, 0, 'clear' )" class="input-group-text" style="background:white; font-size: 14px; padding: 9px;" >
                                <i class="far fa-trash-alt" style="color: #fc4545;"></i>
                            </span>
            
                            <input type="number" class="form-control text-center" [value]="item.unit" min="1" [max]="item.stock" #valorInput style="font-size: 14px;" (keyup)="aggCarrito( item, valorInput.value, 'change' )">
            
                            <span class="input-group-text" style="background: linear-gradient(
                                89.96deg,#24d683 1.05%,#1dc778 17.07%,#00af71 99.96%); font-size: 14px; padding: 9px;" (click)="aggCarrito( item, item.unit, 'plus' )">
            
                                <i class="fas fa-plus" style="color: white;"></i>
            
                            </span>

                        </div>
        
                    <!-- </div> -->
        
                </div>
        
            </div>
        
            <div (click)="carritoService.removeCaritot(item)" class="col-1 p-0">
        
                <div>
                    <i class="fas fa-times" style="font-size: 20px;"></i>
                </div>
                
            </div>
        
        </div>
    
      </div>
  
    </div>
  
    <!-- footer  -->
    <div class="offcanvas-footer p-2 sombra">

      <div class="d-flex justify-content-between">
  
          <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
              <span> Total </span>
          </div>
  
          <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
              <span> {{ carritoService.total | currencyPrecio }} </span>
          </div>
  
      </div>
  
      <div class="d-grid gap-2">
          <button [disabled]="carritoService.dataCarrito.length == 0" class="btn btn-default" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="color:#fff" type="button" (click)="continuar(false)">Continuar <i class="fas fa-arrow-right"></i></button>
      </div>
    </div>
    <!-- data-bs-toggle="offcanvas" data-bs-target="#offcanvasChekout" aria-controls="offcanvasChekout" -->
    </ng-container>

    <ng-container *ngIf="!carritoofcanvas">
         <!-- HEADER  -->
    <div class="offcanvas-header px-0 pt-0 pb-3 sombra">
  
        <div class="d-flex bd-highlight">
    
            <div class="p-0 bd-highlight flex-shrink-1"> 
    
                <button type="button" class="text-reset" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="border-bottom-right-radius: 10px;padding:15px;border: 0;"  (click)="continuar(true)"> <i class="fas fa-arrow-left text-white"></i> </button>
    
            </div>
    
            <div class="p-2 w-100 bd-highlight" style="margin-top:5px;"> 
    
                 <h5 id="offcanvasRightLabel"><i class="fas fa-wallet"></i> CHECKOUT </h5>
    
            </div>
            
        </div>
    
    </div>

    <div style="padding: 0rem 1rem;" class="pt-2">

        <h5> Metodos de entrega </h5> <span> Completa tu pedido!. Los campos que tengan <sup class="text-danger">(*)</sup> son obligatorios</span>
                    
        <div class="row mt-2">

            <div class="col-6">

                <div *ngIf="storeService.dataStore[0].delivery.length > 0" class="d-grid gap-2">

                    <button (click)="methodEntrega('delivery', storeService.dataStore[0])" class="btn btn-default" [ngClass]="{'btn-select-delivery': storeService.dataStore[0].deliverySelect, 'btn-select-entrega': !storeService.dataStore[0].deliverySelect}" type="button">
                        <i class="far " [ngClass]="{'fa-check-circle': storeService.dataStore[0].deliverySelect, 'fa-circle': !storeService.dataStore[0].deliverySelect}"></i> {{ storeService.dataStore[0].typeentrega.length > 0 ? storeService.dataStore[0].typeentrega[0] : 'DELIVERY' }} </button>
                    
                </div>

            </div>

            <div class="col-6">

                <div *ngIf="storeService.dataStore[0].entrega_personal" class="d-grid gap-2">

                    <button (click)="methodEntrega('entrega', storeService.dataStore[0])" class="btn btn-default" [ngClass]="{'btn-select-delivery': storeService.dataStore[0].entregraSelect, 'btn-select-entrega': !storeService.dataStore[0].entregraSelect}" type="button">
                        <i class="far" [ngClass]="{'fa-check-circle': storeService.dataStore[0].entregraSelect, 'fa-circle': !storeService.dataStore[0].entregraSelect}"></i> {{ storeService.dataStore[0].typeentrega.length > 0 ? storeService.dataStore[0].typeentrega[1] : 'RECOGER' }} </button>

                </div>

            </div>

        </div>

    </div>

    <!-- class="mt-3" -->
    <form [ngClass]="{'d-block': storeService.dataStore[0].deliverySelect, 'd-none': !storeService.dataStore[0].deliverySelect}"  style="overflow-y: auto !important;" autocomplete="off" [formGroup]="d" (ngSubmit)="sendDelivery()">

        <div class="offcanvas-body" style="overflow-y: auto !important;">

            <div class="container">

                <!-- Nombre -->
            
                <div class="form-group mt-2">
            
                    <label for="inputNombre" class="label-input">Nombre de quien recibe<span class="text-danger">*</span></label>
            
                    <input id="inputNombre" class="form-control" type="text" formControlName="nombre" placeholder="Nombre" [ngClass]="{'is-invalid': invaldField('nombre') }" required>
            
                    <span class="invalid-feedback" *ngIf="nombre.invalid && nombre.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el nombre </span>
                    <!-- <span class="invalid-feedback" *ngIf="nombre.invalid && nombre.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el nombre sin caracteres</span> -->
                     
                </div>
            
                <!-- Apellido -->
            
                <div class="form-group mt-2">
            
                    <label for="inputApellido" class="label-input">Apellido de quien recibe<span class="text-danger">*</span></label>
            
                    <input id="inputApellido" class="form-control" type="text" formControlName="apellido" placeholder="Apellido" [ngClass]="{'is-invalid': invaldField('apellido') }" required>
            
                    <span class="invalid-feedback" *ngIf="apellido.invalid && apellido.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el apellido </span>
                    <!-- <span class="invalid-feedback" *ngIf="apellido.invalid && apellido.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el apellido sin caracteres</span> -->
                    
                </div>
            
                <!-- NUMERO DE DOCUMENTO  -->
                <div class="form-group mt-2">
            
                    <label for="inputDocumento" class="label-input">N. Documento<span class="text-danger">*</span></label>
            
                    <input id="inputDocumento" class="form-control" type="text" formControlName="documento" placeholder="Numero de documento" [ngClass]="{'is-invalid': invaldField('documento') || documento.invalid && documento.errors?.pattern }" pattern="[0-9a-zA-Z ]{1,50}" required>
            
                    <span class="invalid-feedback" *ngIf="documento.invalid && documento.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el documento </span>
                    <span class="invalid-feedback" *ngIf="documento.invalid && documento.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el documento sin caracteres</span> 
                    
                </div>
            
                <!-- TELEFONO  -->
                <div class="form-group mt-2">
            
                    <label for="inputTelefono" class="label-input">Telefono<span class="text-danger">*</span></label>
            
                    <input id="inputTelefono" class="form-control" type="tel" formControlName="telefono" placeholder="Telefono" [ngClass]="{'is-invalid': invaldField('telefono') || telefono.invalid && telefono.errors?.pattern }" pattern="[-\\0-9]{1,50}" required>
            
                    <span class="invalid-feedback" *ngIf="telefono.invalid && telefono.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el telefono </span>
                    <span class="invalid-feedback" *ngIf="telefono.invalid && telefono.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el telefono sin caracteres</span>
                    
                </div>
            
                <!-- Email -->
                <div class="form-group mt-2">
            
                    <label for="inputEmail" class="label-input">Email (opcional)</label>
            
                    <input id="inputEmail" class="form-control" type="email" placeholder="Email" formControlName="email" [ngClass]="{'is-invalid': invaldField('email') || email.invalid }" email>
            
                    <span class="invalid-feedback" *ngIf="email.invalid"><i class="fas fa-exclamation-circle"></i> Por favor escribe el email correctamente</span>
                    
                </div>

                <!-- Delivery gratis  -->
                <div *ngIf="showDelivery" class="form-group mt-2">

                    <label for="inputZonaentrega" class="label-input">Delivery</label>

                    <input id="inputDelivery" class="form-control" type="text" placeholder="Email" formControlName="delivery" readonly>

                </div>
            
                <!-- Zona de entrega -->
                <div class="form-group mt-2">
            
                    <label for="inputZonaentrega" class="label-input">Zona de entrega <span class="text-danger">*</span></label>
            
                    <select class="form-control" id="inputZonaentrega" formControlName="deliveryin" [ngClass]="{'is-invalid': invaldField('deliveryin') }" (change)="cambioDelivery( storeService.dataStore[0].delivery, precioDely.value  )" #precioDely required>

                        <option selected disabled value="">--Seleccione una zona--</option>
                        <!--  -->
                        <!-- <option [disabled]="carritoService.total < storeService.dataStore[0].delivery_gratis[1]" [ngClass]="{'d-block': carritoService.total > storeService.dataStore[0].delivery_gratis[1], 'd-none':  carritoService.total < storeService.dataStore[0].delivery_gratis[1] }" [value]="storeService.dataStore[0].delivery_gratis[2]">Delivery gratis</option>  -->

                        <option *ngFor="let item of storeService.dataStore[0].delivery; let indice = index" [value]="indice"> {{ item.zona }} </option>

                    </select>
                    
                    <!-- <span class="invalid-feedback" *ngIf="deliveryin.invalid && deliveryin.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor seleccionar </span> -->
                    
                </div>
            
                <!-- DIRECCION ESCRITA -->
                <div class="form-group mt-2">
            
                    <label for="inputDireccion" class="label-input">Direccion de despacho <span class="text-danger">*</span></label>
            
                    <input id="inputDireccion" class="form-control" type="text" [ngClass]="{'is-invalid': invaldField('direccion') || direccion.invalid && direccion.errors?.pattern }" formControlName="direccion" placeholder="Direccion" pattern="[(\\)\\,\\.\\;\\:\\¡\\¿\\?\\!\\/\\´\\#\\_\\,\\.\\-\\0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]{1,700}" required>
            
                    <span class="invalid-feedback" *ngIf="direccion.invalid && direccion.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe la direccion </span>
                    <span class="invalid-feedback" *ngIf="direccion.invalid && direccion.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe la direccion sin caracteres</span>
                    
                </div>
            
                <!-- METODOS DE PAGO  -->
                <div class="form-group mt-2">
            
                    <label for="inputmetodoPago" class="label-input">Metodos de pago <span class="text-danger">*</span></label>
            
                    <select class="form-control" id="inputmetodoPago" [ngClass]="{'is-invalid': invaldField('methodPago') }" formControlName="methodPago" #metodo required>

                        <option selected disabled value="">--Selecciona--</option>
                        <option *ngFor="let item of storeService.dataStore[0].metodos_pagos" value="{{item.banco}}_{{ item.datos }}"> {{ item.banco }} </option>

                    </select>

                    <span class="invalid-feedback" *ngIf="methodPago.invalid && methodPago.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor seleccionar </span>

                    <!--=====================================
                    INFORMACION DEL PAGO SELECCIONADO
                    ======================================-->
                
                    <div class="mb-2 mt-3" *ngFor="let item of storeService.dataStore[0].metodos_pagos">
                
                        <div *ngIf="item.banco == (metodo.value.split('_')[0])" style="border: #DDDDDD 2px dashed; border-radius: 12px; padding: 15px; background: #f8f8f8">
                
                            <h5>Informacion de pago</h5>
                
                            <hr> {{ item.datos }}
                
                            <br *ngIf="item.link != '' && item.link != undefined">
                
                            <a *ngIf="item.link != '' && item.link != undefined" [href]="item.link" target="_blank">Enlace: {{item.link}}</a>
                
                        </div>
                
                    </div>
            
                </div>

                <!--=====================================
                CODIGO PARA CUPON DE DESCUENTO
                ======================================-->
                <div class="form-group mt-2">

                    <label for="inputcupon" class="label-input"><i class="fas fa-ticket-alt"></i> Aplicar codigo de descuento</label>

                    <input id="inputcupon" class="form-control" type="text" placeholder="Aplicar cupon" formControlName="cupon" [ngClass]="{'is-invalid': invaldField('cupon') || cupon.invalid && cupon.errors?.coupon || cupon.invalid && cupon.errors?.pattern }" pattern="[0-9a-zA-Z ]{1,50}">

                    <span class="invalid-feedback" *ngIf="cupon.invalid && cupon.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el cupon sin caracteres</span>
                    <span class="invalid-feedback" *ngIf="cupon.invalid && cupon.errors?.coupon"><i class="fas fa-exclamation-circle"></i> Este cupon no esta disponible</span>

                </div>
            
                <!-- BUSCAR DIRECCION DE DESCPACHO MAPS-->
                <div class="form-group mt-2" *ngIf="storeService.allDataStore[0].store != 'maxipollos-delivery' && storeService.allDataStore[0].store != 'sinteco-barquisimeto'">
            
                    <label class="label-input">Marca tu ubicación de despacho (opcional)</label>
            
                    <div id="geocoder" #asGeocoder class="geocoder" style="position: relative;
                    left: 12px;
                    top: 28px;
                    width: 33.33%;"></div>
            
                    <div id="map" class="map-container" style="margin-top: -35px;">
            
                    </div>
            
                </div>

                <!--=====================================
                NOTA
                ======================================-->
                <div class="form-group mt-2">

                    <label for="inputnota" class="label-input"> Aclarar algo sobre el pedido? </label>

                    <textarea id="inputnota" class="form-control" placeholder="Nota sobre tu pedido" formControlName="nota" [ngClass]="{'is-invalid': invaldField('nota') || nota.invalid && nota.errors?.pattern }" pattern="[(\\)\\,\\.\\;\\:\\¡\\¿\\?\\!\\/\\´\\#\\_\\,\\.\\-\\0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]{1,700}"></textarea>

                    <span class="invalid-feedback" *ngIf="nota.invalid && nota.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el nota sin caracteres</span>

                </div>

            </div>
        
        </div>

        <!-- footer  -->

        <div class="offcanvas-footer p-2 sombra">

            <div class="d-flex justify-content-between">
            
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-size: 17px;">
                    <span> Subtotal </span>
                </div>
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-size: 17px;">
                    <span> {{ carritoService.total | currencyPrecio }}</span>
                </div>
        
            </div>
        
            <div class="d-flex justify-content-between">
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem;">
                    <span> Delivery </span>
                </div>
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem;">
                    <span> {{ precioDelibery | currencyPrecio }} </span>
                </div>
        
            </div>
        
            <div class="d-flex justify-content-between">
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span> Total </span>
                </div>
        
                <div *ngIf="precioDescuento != 0" class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span style="text-decoration: line-through;"> {{ (carritoService.total + precioDelibery) | currencyPrecio }} </span>
                </div>

                <div *ngIf="precioDescuento == 0" class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span> {{ (carritoService.total + precioDelibery) | currencyPrecio }} </span>
                </div>
        
            </div>

            <div *ngIf="precioDescuento != 0" class="d-flex justify-content-between">
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span> Descuento</span>
                </div>
        
                <div  class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span class="text-danger" style="font-size: 12px;"> -{{precioDescuento}}% OFF </span> <span> {{ (carritoService.total + precioDelibery) - ( (carritoService.total + precioDelibery) * precioDescuento / 100 ) | currencyPrecio }} </span>
                </div>
        
            </div>

            <p *ngIf="mensaje" class="text-danger text-center mt-1 mb-0">* Por favor seleccione la zona de entrega correctamente *</p>

            <p *ngIf="d.invalid" class="text-danger text-center mt-1 mb-0">* Por favor llenar los campos obligatorios *</p>

            <div class="d-grid gap-2">

                <button *ngIf="!preloadBoton" class="btn btn-default" style="background: linear-gradient(89.96deg,#24d683 1.05%,#1dc778 17.07%,#00af71 99.96%); color:#fff" type="submit"><i class="fab fa-whatsapp"></i> Completar pedido</button>

                <button *ngIf="preloadBoton" class="btn btn-primary" type="button" style="background: linear-gradient(89.96deg,#24d683 1.05%,#1dc778 17.07%,#00af71 99.96%); color:#fff" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                     Cargando...
                </button>

            </div>

            <p *ngIf="this.storeService.dataStore[0].mensaje_pago != ''" class="text-muted text-center m-0">{{ this.storeService.dataStore[0].mensaje_pago }}</p>
        
        </div>

    </form>

    <form [ngClass]="{'d-block': storeService.dataStore[0].entregraSelect, 'd-none': !storeService.dataStore[0].entregraSelect}" style="overflow-y: auto !important;"  autocomplete="off" [formGroup]="e" (ngSubmit)="sendEntrega()">

        <div class="offcanvas-body" style="overflow-y: auto !important;">

            <div class="container">

                <!-- Nombre -->

                <div class="form-group mt-2">

                    <label for="inputNombre1" class="label-input">Nombre de quien recibe<span class="text-danger">*</span></label>

                    <input id="inputNombre1" class="form-control" type="text" formControlName="nombre" placeholder="Nombre" [ngClass]="{'is-invalid': invaldField1('nombre') }" required>
            
                    <span class="invalid-feedback" *ngIf="nombre1.invalid && nombre1.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el nombre </span>
                    <!-- <span class="invalid-feedback" *ngIf="nombre1.invalid && nombre1.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el nombre sin caracteres</span> -->
                
                </div>

                <!-- Apellido -->

                <div class="form-group mt-2">

                    <label for="inputApellido1" class="label-input">Apellido de quien recibe<span class="text-danger">*</span></label>

                    <input id="inputApellido1" class="form-control" type="text" formControlName="apellido" placeholder="Apellido" [ngClass]="{'is-invalid': invaldField1('apellido') }" required>

                    <span class="invalid-feedback" *ngIf="apellido1.invalid && apellido1.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el apellido </span>
                    <!-- <span class="invalid-feedback" *ngIf="apellido1.invalid && apellido1.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el apellido sin caracteres</span> -->
                
                </div>

                <!-- NUMERO DE DOCUMENTO  -->
                <div class="form-group mt-2">

                    <label for="inputDocumento1" class="label-input">N. Documento<span class="text-danger">*</span></label>

                    <input id="inputDocumento1" class="form-control" formControlName="documento" [ngClass]="{'is-invalid': invaldField1('documento') || documento1.invalid && documento1.errors?.pattern }" type="text" placeholder="Numero de documento" pattern="[0-9a-zA-Z]{1,50}" required>

                    <span class="invalid-feedback" *ngIf="documento1.invalid && documento1.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el documento </span>
                    <span class="invalid-feedback" *ngIf="documento1.invalid && documento1.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el documento sin caracteres</span>
                
                </div>

                <!-- TELEFONO  -->
                <div class="form-group mt-2">

                    <label for="inputTelefono1" class="label-input">Telefono<span class="text-danger">*</span></label>

                    <input id="inputTelefono1" class="form-control" type="tel" formControlName="telefono" [ngClass]="{'is-invalid': invaldField1('telefono') || telefono1.invalid && telefono1.errors?.pattern }" placeholder="Telefono" pattern="[-\\0-9]{1,50}" required>
            
                    <span class="invalid-feedback" *ngIf="telefono1.invalid && telefono1.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor escribe el telefono </span>
                    <span class="invalid-feedback" *ngIf="telefono1.invalid && telefono1.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el telefono sin caracteres</span>
                
                </div>

                <!-- Email -->
                <div class="form-group mt-2">

                    <label for="inputEmail1" class="label-input">Email (opcional)</label>

                    <input id="inputEmail1" class="form-control" type="email" placeholder="Email" formControlName="email" [ngClass]="{'is-invalid': invaldField1('email') || email1.invalid }" email>
            
                    <span class="invalid-feedback" *ngIf="email1.invalid"><i class="fas fa-exclamation-circle"></i> Por favor escribe el email correctamente</span>
                
                </div>

                <!-- METODOS DE PAGO  -->
                <div class="form-group mt-2">

                    <label for="inputmetodoPago1" class="label-input">Metodos de pago <span class="text-danger">*</span></label>

                    <select class="form-control" id="inputmetodoPago1" [ngClass]="{'is-invalid': invaldField1('methodPago') }" formControlName="methodPago" #metodo1 required>
                        <option value="">--Selecciona--</option>
                        <option *ngFor="let item of storeService.dataStore[0].metodos_pagos" value="{{item.banco}}_{{ item.datos }}"> {{ item.banco }} </option>
                    </select>

                    <span class="invalid-feedback" *ngIf="methodPago1.invalid && methodPago1.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor seleccionar </span>

                    <!--=====================================
                    INFORMACION DEL PAGO SELECCIONADO
                    ======================================-->

                    <div class="mb-2 mt-3" *ngFor="let item of storeService.dataStore[0].metodos_pagos">

                        <div *ngIf="item.banco == (metodo1.value.split('_')[0])" style="border: #DDDDDD 2px dashed; border-radius: 12px; padding: 15px; background: #f8f8f8">

                            <h5>Informacion de pago</h5>

                            <hr> {{ item.datos }}

                            <br *ngIf="item.link != '' && item.link != undefined">

                            <a *ngIf="item.link != '' && item.link != undefined" [href]="item.link" target="_blank">Enlace: {{item.link}}</a>

                        </div>

                    </div>

                </div>

                <!--=====================================
                SELECCIONA SITOS DE RETIRO SI EXINTEN
                ======================================-->
                
                <div *ngIf="storeService.dataStore[0].sitiosRetiro.length > 0" class="mt-2 form-group">

                    <label for="inputretiros" class="label-input">Escoje el lugar de retiro </label>

                    <select class="form-control" name="valores" id="inputretiros" formControlName="sitios" #sitioretiros>
                        <option value="">--Seleccione una zona--</option>
                        <option *ngFor="let item of storeService.dataStore[0].sitiosRetiro" [value]="item.nombreSitio"> {{ item.nombreSitio }} </option>
                    </select>

                    <!-- <span class="invalid-feedback" *ngIf="sitios.invalid && sitios.errors?.required"><i class="fas fa-exclamation-circle"></i> Por favor seleccionar </span> -->

                    <!-- MOSTRAR SITOOS DE RETIRO  -->
                    <div class="mb-2 mt-3" *ngFor="let item of storeService.dataStore[0].sitiosRetiro">

                        <div *ngIf="item.nombreSitio == sitioretiros.value" style="border: #DDDDDD 2px dashed; border-radius: 12px; padding: 15px; background: #f8f8f8">

                            <h5>Direccion de retiro</h5>

                            <hr> {{ item.direccion }}

                        </div>

                    </div>
                    
                </div>

                <!--=====================================
                NOTA
                ======================================-->
                <div class="form-group mt-2">

                    <label for="inputnota1" class="label-input"> Aclarar algo sobre el pedido? </label>

                    <textarea id="inputnota1" class="form-control" placeholder="Nota sobre tu pedido" formControlName="nota" [ngClass]="{'is-invalid': invaldField1('nota') || nota1.invalid && nota1.errors?.pattern }" pattern="[(\\)\\,\\.\\;\\:\\¡\\¿\\?\\!\\/\\´\\#\\_\\,\\.\\-\\0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]{1,700}"></textarea>

                    <span class="invalid-feedback" *ngIf="nota1.invalid && nota1.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el nota sin caracteres</span>

                </div>

                <!--=====================================
                CODIGO PARA CUPON DE DESCUENTO
                ======================================-->
                <div class="form-group mt-2">

                    <label for="inputcupon1" class="label-input"><i class="fas fa-ticket-alt"></i> Aplicar codigo de descuento</label>

                    <input id="inputcupon1" class="form-control" type="text" placeholder="Aplicar cupon" formControlName="cupon" [ngClass]="{'is-invalid': invaldField1('cupon') || cupon1.invalid && cupon1.errors?.coupon || cupon1.invalid && cupon1.errors?.pattern }" pattern="[0-9a-zA-Z ]{1,50}">

                    <span class="invalid-feedback" *ngIf="cupon1.invalid && cupon1.errors?.pattern"><i class="fas fa-exclamation-circle"></i> Por favor escribe el cupon sin caracteres</span>
                    <span class="invalid-feedback" *ngIf="cupon1.invalid && cupon1.errors?.coupon"><i class="fas fa-exclamation-circle"></i> Este cupon no esta disponible</span>


                </div>

            </div>
        
        </div>

        <!-- footer  -->

        <div class="offcanvas-footer p-2 sombra">

            <div class="d-flex justify-content-between">
            
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-size: 17px;">
                    <span> Subtotal </span>
                </div>
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-size: 17px;">
                    <span> {{ carritoService.total | currencyPrecio }}</span>
                </div>
        
            </div>
        
            <div class="d-flex justify-content-between">
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem;">
                    <span> Delivery </span>
                </div>
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem;">
                    <span> {{ 0 | currencyPrecio }}</span>
                </div>
        
            </div>
        
            <div class="d-flex justify-content-between">
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span> Total </span>
                </div>
        
                <div *ngIf="precioDescuento != 0" class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span style="text-decoration: line-through;"> {{ (carritoService.total) | currencyPrecio }} </span>
                </div>

                <div *ngIf="precioDescuento == 0" class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span> {{ (carritoService.total) | currencyPrecio }} </span>
                </div>
        
            </div>

            <div *ngIf="precioDescuento != 0" class="d-flex justify-content-between">
        
                <div class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span> Descuento</span>
                </div>
        
                <div  class="px-2 bd-highlight" style="padding-bottom: 0.25rem; font-weight: 700; font-size: 17px;">
                    <span class="text-danger" style="font-size: 12px;"> -{{precioDescuento}}% OFF </span> <span> {{ (carritoService.total) - ( (carritoService.total) * precioDescuento / 100 ) | currencyPrecio }} </span>
                </div>
        
            </div>

            <p *ngIf="e.invalid" class="text-danger text-center mt-1 mb-0">* Por favor llenar los campos obligatorios *</p>

            <div class="d-grid gap-2">

                <button *ngIf="!preloadBoton" class="btn btn-default" style="background: linear-gradient(89.96deg,#24d683 1.05%,#1dc778 17.07%,#00af71 99.96%); color:#fff" type="submit"><i class="fab fa-whatsapp"></i> Completar pedido</button>

                <button *ngIf="preloadBoton" class="btn btn-primary" type="button" style="background: linear-gradient(89.96deg,#24d683 1.05%,#1dc778 17.07%,#00af71 99.96%); color:#fff" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                     Cargando...
                </button>

            </div>

            <p *ngIf="this.storeService.dataStore[0].mensaje_pago != ''" class="text-muted text-center mt-1 mb-0">{{ this.storeService.dataStore[0].mensaje_pago }}</p>

        </div>

    </form>
    </ng-container>

</div>


<!-- MODAL PARA CAMBIO DE MONEDA  -->
<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasCambiomodeda" aria-labelledby="cambiomonedaModalLabel" style="height: 53%;">

    <div class="offcanvas-header">

        <h5 class="offcanvas-title" id="cambiomonedaModalLabel">Cambio de moneda</h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>

    </div>

    <div class="offcanvas-body">

        <h6>Selecciona la moneda</h6>

        <div class="list-group">

            <!-- active -->
            <div *ngFor="let item of storeService.allDataStore[0].monerda">
        
                <a (click)="setCurrency( item, storeService.allDataStore[0].monerda )" [ngClass]="{'activar-moneda': item.atc}" *ngIf="item.value != 0" class="list-group-item list-group-item-action text-center"  style="font-weight: 700;" aria-current="true" data-bs-dismiss="offcanvas" aria-label="Close">

                    {{ item.symbol }}

                </a>

            </div>
            
        </div>
     
    </div>

</div>


<!-- MODAL CHEKOUT -->
