import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalOfertaComponent } from './modal-oferta.component';

@NgModule({
  declarations: [ ModalOfertaComponent ],
  exports: [
    ModalOfertaComponent
  ],
  imports: [
    CommonModule,
  ]
})
export class ModalOfertaModule { }
