import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'urlvideo'
})
export class UrlvideoPipe implements PipeTransform {

  constructor( private sanitazer: DomSanitizer ){}

  transform(url:string ): SafeResourceUrl {
    return this.sanitazer.bypassSecurityTrustResourceUrl(url);
  }

}
