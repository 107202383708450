import { Directive, ElementRef, HostListener, Input } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';
import { ProductService } from '../services/product/product.service';

@Directive({
  selector: '[appImgdirective]'
})
export class ImgdirectiveDirective {

  @Input() urlCustom: any;

  constructor( private elmentRef: ElementRef, private ProductService: ProductService ) 
  { 
    this.ProductService.dataImg = []; 

  }

  // @HostListener('error')
  // onError():void {

  //   console.log( 'Esta imagen esta rota', this.elmentRef.nativeElement )

  // }


  @HostListener('load')
  onLoad(): void  {

    if( this.elmentRef.nativeElement ){

      setTimeout( ()=>{ this.ProductService.dataImg.push( this.urlCustom ) },6)
    }

  }

}
