import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  Api:string = environment.Api;
  allDataStore: any[] = [{ preload: false, color: '', moneda:'[]', monedaPrincipal: '' }];

  dataStore:any[] = [{
    codigo_descuento: [],
    delivery: [{ zona:'', precio: '' }],
    deliverySelect: false,
    delivery_gratis: ['>', 99999999999999, 'gratis'],
    entrega_personal: false,
    entregraSelect: false,
    mensaje_pago: "",
    metodos_pagos: [{banco:'', datos: '', link: ''}],
    sitiosRetiro: [{direccion: '', nombreSitio: ''}],
    telefono: '031321',
    url: 'adf',
    tienda: '',
    sliderTienda1: '',
    sliderTienda2:'',
    sliderTienda3:'',
    sliderTienda4:'',
    typeentrega:[]
  }];

  slider:any[] = ['', '', '', '' ];
  slider1:any[] = [];

  currency = { principal:'' ,symbol: '', value: 1 }

  abierto = true;

  //SE CREA UN OBJETO CON LA PROPIEDAD BehaviorSubject para divulgarlo en toda la aplicacion
  currency$: BehaviorSubject<any> = new BehaviorSubject<any>( this.currency )
  delivery$: BehaviorSubject<any> = new BehaviorSubject<any>( ['>', 99999999999999, 'gratis'] )

  constructor( private http: HttpClient ) { }

  getStores(){

    return this.http.get(`${this.Api}tiendas.json`);

  }

  getFilterDataStore(orderBy:string, equalTo:string){

    return this.http.get(`${this.Api}tiendas.json?orderBy="${orderBy}"&equalTo="${equalTo}"&print=pretty`)
      .pipe(

        map( (resp:any)=>{ 
          Object.keys( resp ).map( a=>{

            this.currency.principal = resp[a].moneda_principal;
            this.currency.symbol = resp[a].moneda_principal;

            this.allDataStore[0].monedaPrincipal = resp[a].moneda_principal;
            this.allDataStore[0].preload = true,
            this.allDataStore[0].store = resp[a].url;
            this.allDataStore[0].color = resp[a].color
            this.allDataStore[0].monerda = [ 

              { value: 1, symbol: resp[a].moneda_principal, atc: false },
              { value: resp[a].dolar_bolivares, symbol: 'BsS', atc: false }, 
              { value: resp[a].pesos_dolar , symbol:  resp[a].moneda_principal == 'USD' ? 'COP$' : '$', atc: false }, 
              { value: resp[a].dolar_euros, symbol: '€', atc: false } , 
              { value: resp[a].chilenos, symbol: 'CLP$', atc: false }  ,  
              { value: resp[a].argentinos, symbol: 'ARS$', atc: false }  

            ]

            //COMPARAR FECHA PARA EL CODIGO DE CUPON DE DESCUENTO
            let today = new Date();
            let offerDate;
           
            if(resp[a].codigo_descuento != ''){

              offerDate = new Date(

                parseInt(JSON.parse(resp[a].codigo_descuento)[3].split("-")[0]),
                parseInt(JSON.parse(resp[a].codigo_descuento)[3].split("-")[1]) - 1,
                parseInt(JSON.parse(resp[a].codigo_descuento)[3].split("-")[2])
  
              )

            }else{

              offerDate = new Date();

            }

            this.dataStore = [
              {
                codigo_descuento: today < offerDate ? JSON.parse(resp[a].codigo_descuento) : [],
                delivery: resp[a].delivery == "" ? [] : JSON.parse( resp[a].delivery ),
                deliverySelect: resp[a].delivery != '' ? true : false,
                delivery_gratis: resp[a].delivery_gratis == "" ? ['>', 99999999999999, 'gratis'] : [  JSON.parse( resp[a].delivery_gratis )[0], JSON.parse( resp[a].delivery_gratis )[1], 'gratis'],
                entrega_personal: resp[a].entrega_personal,
                entregraSelect: resp[a].delivery != '' ? false : true,
                mensaje_pago: resp[a].mensaje_pago,
                metodos_pagos: JSON.parse(resp[a].metodos_pagos),
                sitiosRetiro: resp[a].sitiosRetiro == "" || resp[a].sitiosRetiro == undefined  ? [] : JSON.parse( resp[a].sitiosRetiro ),
                telefono: resp[a].telefono,
                url: resp[a].url,
                tienda: resp[a].tienda,
                sliderTienda1: resp[a].sliderTienda1 == undefined || resp[a].sliderTienda1 == '' ? this.slider1[0] = `${ environment.Server }assets/img/stores/` : this.slider1[0] = `${ environment.Server }assets/img/stores/${ resp[a].url }/${ resp[a].sliderTienda1 }`,
                sliderTienda2: resp[a].sliderTienda2 == undefined || resp[a].sliderTienda2 == '' ? this.slider1[1] = `${ environment.Server }assets/img/stores/` : this.slider1[1] = `${ environment.Server }assets/img/stores/${ resp[a].url }/${ resp[a].sliderTienda2 }`,
                sliderTienda3: resp[a].sliderTienda3 == undefined || resp[a].sliderTienda3 == '' ? this.slider1[2] = `${ environment.Server }assets/img/stores/` : this.slider1[2] = `${ environment.Server }assets/img/stores/${ resp[a].url }/${ resp[a].sliderTienda3 }`,
                sliderTienda4: resp[a].sliderTienda4 == undefined || resp[a].sliderTienda4 == '' ? this.slider1[3] = `${ environment.Server }assets/img/stores/` : this.slider1[3] = `${ environment.Server }assets/img/stores/${ resp[a].url }/${ resp[a].sliderTienda4 }`,
                typeentrega: resp[a]?.typeentrega ?  resp[a]?.typeentrega : []
              } 
            ]

            this.delivery$.next(resp[a].delivery_gratis == "" ? ['>', 99999999999999, 'gratis'] : [  JSON.parse( resp[a].delivery_gratis )[0], JSON.parse( resp[a].delivery_gratis )[1], 'gratis'])

            this.slider = [];

            this.slider1.forEach(element => {

              if( element !=  `${ environment.Server }assets/img/stores/` ){
                this.slider.push( element )
              }
              
            });

          })

          return resp
        
        })

      )

  }
  //CREAR MONEDAS
  setCurrency( symbol:any, value:any ):void{

    const data = {
      principal: this.currency.principal,
      symbol,
      value

    }

    this.currency$.next( data )

  }

  //CONTROL DE TIENDAS
  getFilterDataControlTienda(orderBy:string, equalTo:string){

		return this.http.get(`${this.Api}control-tiendas.json?orderBy="${orderBy}"&equalTo="${equalTo}"&print=pretty`);

  }

}
