import { Component, HostListener, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StoreService } from '../../services/store/store.service';

import { Store } from '../../interface/store.interface';
import { environment } from '../../../environments/environment.prod';
import { CategoriasService } from '../../services/categoria/categorias.service';
import { Categoriain } from '../../interface/categoria.interface';
import { ProductService } from '../../services/product/product.service';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { ModalOfertaComponent } from '../../pages/main-page/modal-oferta/modal-oferta.component';
import { ModalSucursalComponent } from 'src/app/pages/main-page/modal-sucursal/modal-sucursal.component';
import { Subject } from 'rxjs';

declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  
  public f = this.form.group({

    busqueda:[ '', [ Validators.required, Validators.pattern('[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*') ] ]

  })

  get busqueda(){ return this.f.controls.busqueda }

  /*=============================================
	VALIDAR ENVIO DE FORMULARIO
	=============================================*/
  formSubmited:boolean = false;


  dataStore: Store[] = [];
  path:string = environment.Server;
  preload:boolean = true;
  socialProperty:any [] = [];
  socialValue:any[] = [];

  dataCategorias: Categoriain[] = [];

  classSticky:boolean = false;
  contar:number = 0;
  valorStiky:any;

  tienda:string;

  closeResult:string = '';
  contadorModal:number = 0;
  tiendaUrl:string = '';

  action$:Subject<any>;

  constructor( @Inject(DOCUMENT) private document: Document, 
  private router: Router,
  private storeService: StoreService, 
  public categoriaService: CategoriasService, 
  private activatedRoute: ActivatedRoute, 
  public ProductService: ProductService,
  private form: FormBuilder,
  private title:Title,
  private modalService: NgbModal ) { }

  //MENU PEGAJOSO CON SCROLL
  @HostListener('window:scroll')
  onWinScroll():void{

    // console.log( 'hola!!' )
    this.contar++;
    const yOffset = window.pageYOffset;
    const scrollTop = this.document.documentElement.scrollTop;
    const positionMenu: any = document.getElementById("menuProducto")?.offsetTop;

    
    // const position = $("#margarinas").offset().top + $("#margarinas").parent().scrollTop() - $("#margarinas").offsetParent().offset().top

    // console.log( 'position', position )
    
    if(this.contar == 1 ){

      this.valorStiky = positionMenu

    }

    this.classSticky = ( yOffset || scrollTop ) > this.valorStiky ? true : false;
    
  }

  ngOnInit(): void {

    //Si hay ofertas en los productos muestra el modal
    this.action$ = this.ProductService.productOfert;
    this.action$.subscribe( (resp:any)=>{
      // console.log('por aquii', resp)
      if( resp.length > 0 && this.router.url.split('/')[3] != 'ofertas' && this.contadorModal == 0){

        setTimeout(()=>{
          const categorias = this.categoriaService.dataCategoira;
          const productsOfert = resp.map( (val)=> val.categoria )
  
          const categoriamodel = categorias.filter((val)=> productsOfert.includes(val.url) ).map( (val)=>val.nombre );
  
          this.contadorModal++;
  
          const modalRef = this.modalService.open( ModalOfertaComponent, {ariaLabelledBy: 'modal-basic-title'})
          modalRef.componentInstance.ofertas = categoriamodel;
        }, 700)

      }

    })

    this.preload = false;

    const url = this.router.url.split('/')[1];
    const urlTienda = url.includes('?') ? url.split('?')[0] : url;

    this.tienda = urlTienda;
    this.tiendaUrl = urlTienda;

    this.storeService.getFilterDataStore( "url", urlTienda )
    .subscribe( ( resp:any )=>{
      
      if( resp[Object.keys( resp )[0]]?.sucursalesactive ){
        
        localStorage.removeItem('sucursal');

        let sucursales = JSON.parse( resp[Object.keys( resp )[0]]?.sucursales )
        const modalRef = this.modalService.open( ModalSucursalComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static', centered: true })
        modalRef.componentInstance.sucursales = sucursales;

      }

      this.preload = true;

      this.dataStore = Object.keys( resp ).map( a=>({

        argentinos: resp[a].argentinos,
        banner: resp[a].bannerPrincipalTienda == 'img/products/default/default-horizontal-slider.jpg' ?  `${ this.path }assets/img/products/default/default-horizontal-slider.jpg` : `${ this.path }assets/img/stores/${resp[a].url}/${resp[a].bannerPrincipalTienda}`,
        botonShopping: resp[a].botonShopping,
        chilenos: resp[a].chilenos,
        codigo_descuento: resp[a].codigo_descuento,
        color: resp[a].color,
        cover: resp[a].cover,
        delivery: resp[a].delivery,
        delivery_gratis: resp[a].delivery_gratis,
        descripcion: resp[a].descripcion,
        direccion: resp[a].direccion,
        dolarToday: resp[a].dolarToday,
        dolar_bolivares: resp[a].dolar_bolivares,
        dolar_euros: resp[a].dolar_euros,
        entrega_personal: resp[a].entrega_personal,
        estado: resp[a].estado,
        google_analytics: resp[a].google_analytics,
        horario_apertura: JSON.parse(resp[a].horario_apertura),
        horario_cierre: JSON.parse(resp[a].horario_cierre),
        logo: `${ this.path }assets/img/stores/${resp[a].url}/${resp[a].logo}`,
        mensajeWhatsapp: `https://api.whatsapp.com/send?phone=${resp[a].telefono}&text=${resp[a].mensajeWhatsapp}`,
        mensaje_pago: resp[a].mensaje_pago,
        metodos_pagos: resp[a].metodos_pagos,
        moneda_principal: resp[a].moneda_principal,
        pais: resp[a].pais,
        pesos_dolar: resp[a].pesos_dolar,
        plan: resp[a].plan,
        productos: resp[a].productos,
        sitiosRetiro: resp[a].sitiosRetiro,
        social: JSON.parse(resp[a].social),
        telefono: resp[a].telefono,
        tienda: resp[a].tienda,
        url: resp[a].url,
        usuario: resp[a].usuario,
        whatsapp: resp[a].whatsapp

      }  as Store ))

      // REDES SOCIALES 
      for( const i in this.dataStore[0].social ){

        if( this.dataStore[0].social[i] != '' ){

          this.socialProperty.push( i );
          this.socialValue.push( this.dataStore[0].social[i] )

        }
        
      }

      //Titulo
      this.title.setTitle(this.dataStore[0].tienda)

      //ICON
      $('.img-icon').attr('href', `${this.dataStore[0].logo}`)

      //SCROLL UP
      $('#scrollUp').css({ 'background-color':`${this.dataStore[0].color}`, 'z-index': '100' })
     
      // HORARIOS DE APERTURA 
      this.dataStore[0].horario_apertura.map( (a:any, index:any)=>{

        let actual = new Date().getTime();

        let horaActual = `${ new Date(actual).getHours()}${ new Date( actual ).getMinutes() }`;

        let dia = a.type;

        let horariosA = a.value;
        let horariosC = this.dataStore[0].horario_cierre[index].value;
  
        let splitHoraApertura = '';
        let splitHoraCierre = '';

        let arrayDiasSemana = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];

        let diaActual = new Date().getDay();

        //capturo la hora en numero sin :
        if( horariosA != 'cerrado' && horariosA != '24 horas' ){
 
          //UNO HORA Y MINUTOS
          splitHoraApertura += horariosA.split(':')[0];
          splitHoraApertura += horariosA.split(':')[1];

          //UNO HORA Y MINUTOS CIERRE
          splitHoraCierre += horariosC.split(':')[0];
          splitHoraCierre += horariosC.split(':')[1];
       
        }

        if( arrayDiasSemana[diaActual] == dia && horariosA != 'cerrado' && horariosA != '24 horas' ){

          if( Number(splitHoraApertura) <= Number(horaActual) && Number(horaActual) < Number(splitHoraCierre)){

            this.dataStore[0].abierto = true;
            this.storeService.abierto = true;

          }else if( Number( horaActual) >= Number(splitHoraCierre) || Number( horaActual) < Number(splitHoraCierre) ){

            this.dataStore[0].abierto = false;
            this.storeService.abierto = false;

          }

        }else if( arrayDiasSemana[diaActual] == dia && horariosA == 'cerrado' ){

          this.dataStore[0].abierto = false;
          this.storeService.abierto = false;

        }else if( arrayDiasSemana[diaActual] == dia && horariosA == '24 horas' ){

          this.dataStore[0].abierto = true;
          this.storeService.abierto = true;

        }

      })

      // CATEGORIAS 
      this.categoriaService.categoriagetFilterData( "tienda", this.tiendaUrl )
      .subscribe( (resp1:any)=>{
        
        this.dataCategorias = Object.keys(resp1).map( (a:any)=>({

          icon: resp1[a].icon,
          imagen: resp1[a].imagen,
          imagenprincipal: resp1[a].imagenprincipal,
          nombre: resp1[a].nombre,
          ordernar: resp1[a].ordernar,
          tienda:resp1[a].tienda,
          url: resp1[a].url,

        }) as Categoriain )
        .sort( (a,b):any=>{

          if( a.ordernar ){
    
            return ( Number(a.ordernar) - Number(b.ordernar ) );
    
          }

        })

      })

    })

    //INTRO
    this.storeService.getFilterDataControlTienda( 'tienda', this.tiendaUrl  )
    .subscribe( (resp2:any)=>{

      if( Object.keys(resp2).length > 0 ){

        Object.keys( resp2 ).map( (a)=>{

          if( new Date( resp2[a].fecha_ex ).getTime() > new Date().getTime() ){

            setTimeout( ()=>{

              $('#intro').css({ 'display':'none' })
    
            }, 500)

          }

        })

      }

    })

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  /*=============================================
	VALIDAR QUE LA CATEGORIA NO ESTE REPETIDO
	=============================================*/
  invaldField(campo:any){

    return this.f.get(campo)!.invalid && this.formSubmited ? true : false 

  }

  /*=============================================
  BUSQUEDA DEL PRODUCTO
  =============================================*/
  search(){

    /*=============================================
    VALIDAR ENVIO DE FORMULARIO
	  =============================================*/
    this.formSubmited = true;

    if( this.f.invalid ){

      return

    }

    const resultado = this.busqueda.value.toLowerCase().replace(/[ ]/g, '-');

    this.router.navigate([ this.tiendaUrl, 'search', resultado ])
    
  }

  ngOnDestroy(){
    this.action$.unsubscribe();
  }

}
