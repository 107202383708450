import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgdirectiveDirective } from './imgdirective.directive';
import { ImgRotaDirective } from './img-rota.directive';
import { DeliverygDirective } from './deliveryg.directive';



@NgModule({
  declarations: [ ImgdirectiveDirective, ImgRotaDirective, DeliverygDirective ],
  exports: [ ImgdirectiveDirective, ImgRotaDirective ],
  imports: [
    CommonModule
  ]
})
export class DirectiveModule { }
