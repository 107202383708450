import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalProductComponent } from './modal-product.component';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  declarations: [
    ModalProductComponent 
  ],
  exports:[
    ModalProductComponent
  ],
  imports: [
    CommonModule,
    PipesModule
  ]
})
export class ModalProductModule { }
