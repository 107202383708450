<div class="row row-cols-2 row-cols-md-4 pt-1 g-4">

    <div *ngFor="let item of dataProduct; let i = index; let lastIndex = last" class="col">

        <div class="card card-product">

            <a (click)="modalProducto(item)">

                <!-- <img *ngIf="producService.dataImg[i] == undefined" src="https://46.festivalcervantino.gob.mx/assets/images/ajax-loader-2.gif" class="card-img-top d-block" alt="..." style="border-top-left-radius: 10px; border-top-right-radius: 10px; height: 185px;object-fit: contain;">  -->
                
                <!-- appImgdirective [urlCustom]="item.url" [ngClass]="{'d-block animate__animated animate__fadeIn': producService.dataImg[i] == item.url, 'd-none': producService.dataImg[i] == undefined }" -->
                <img [src]="item.imagen" class="card-img-top" [alt]="item.nombre"  style="border-top-left-radius: 10px; border-top-right-radius: 10px; height: 185px;object-fit: contain;" loading=lazy> 

            </a>

           
            <!-- BODY  -->
            <div class="card-body" style="padding: 0.75rem;">

                <!-- OFERTA  -->

                <div class="offert" style="position:absolute; top: 0.83rem;">

                    <span *ngIf="item.oferta[0] != 'No disponible' && item.oferta[0] != 'Nuevo' && item.oferta[0] != 'Agotado' && item.oferta[0] != undefined" style="background-color: #ff1010; ">{{ item.oferta[0] }}</span>
                    <span *ngIf="item.oferta[0] == 'Nuevo' " style="background-color: #ffb700; ">{{ item.oferta[0] }}</span>
                    <span *ngIf="item.oferta[0] == 'Agotado' " style="background-color: #000; ">{{ item.oferta[0] }}</span>

                    <span *ngIf="item.oferta[0] == 'No disponible' " style="background-color: #000; ">{{ item.oferta[0] }}</span>

                </div>

                <a routerLink="/{{item.tienda}}/p/{{item.id}}" style="text-decoration: none !important; color: #000">

                    <!-- TITULO DEL PRODUCTO  -->

                    <h5 class="card-title text-center text-sm card-titulo">{{ item.nombre }}</h5>

                    <!-- DESCRIPCION DEL PRODCUTO  -->
                    <!-- <div class="card-text text-muted card-descipcion" [innerHTML]="item.descripcion"></div> -->

                    <!-- PRECIOS  -->
                    <span >  
                        <p *ngIf="item.oferta[1] != undefined && item.oferta[2] == undefined" class="mb-0 mt-2" [ngStyle]="{'color': storeService.allDataStore[0].color}" style="font-weight: 500; font-size: 14px;"> {{item.oferta[1] | currencyPrecio }} </p>

                        <p *ngIf="item.oferta[1] != undefined && item.oferta[2] != undefined" class="mb-0 mt-2" [ngStyle]="{'color': storeService.allDataStore[0].color}" style="font-weight: 500; font-size: 14px;"> {{item.oferta[1] | currencyPrecio }} </p>
                        
                        <p *ngIf="item.oferta[1] != undefined && item.oferta[2] != undefined" class="mb-0 mt-2 text-muted" style="font-weight: 300; font-size: 13px; text-decoration: line-through;">{{item.oferta[2] | currencyPrecio }} </p>
                    </span>

                </a>

            </div>

           

            <div class="card-footer" style="background: white; border-top: 0px; padding: 0.75rem; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">

            <div *ngIf="item.especificaciones.length === 0 && storeService.abierto" class="d-grid gap-2">

                <!-- AGREGAR AL CARRITO  -->
                <button [disabled]="!item.estado || item.stock == 0" *ngIf="!item.activar" (click)="aggCarrito( item, 1, 'agg' )" class="btn bnt-sm p-1 activar-{{ item.url }}" type="button" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="font-weight: 600; color: white; font-size: 14px;"><i class="fas fa-shopping-cart" style="font-size: 12px;" ></i> Agregar </button>

                <!-- INPUT CARRITO  -->
                <div *ngIf="item.activar" class="input-group" >
            
                <span *ngIf="(valorInput.value | value ) > 1" (click)="aggCarrito( item, valorInput.value, 'minus' )" class="input-group-text" style="background:white; padding: 9px;" >
                    <i class="fas fa-minus"></i>
                </span>

                <span *ngIf="(valorInput.value | value ) <= 1 || ( valorInput.value | value ) == ''" (click)="aggCarrito( item, 0, 'clear' )" class="input-group-text" style="background:white; font-size: 14px; padding: 9px;" >
                    <i class="far fa-trash-alt" style="color: #fc4545;"></i>
                </span>

                <input type="number" class="form-control text-center valorCarrito-{{ item.id }}" value="1" min="1" [max]="item.stock" #valorInput style="font-size: 14px;" (keyup)="aggCarrito( item, valorInput.value, 'change' )">

                <span class="input-group-text" style="background: linear-gradient(
                    89.96deg,#24d683 1.05%,#1dc778 17.07%,#00af71 99.96%); font-size: 14px; padding: 9px;" (click)="aggCarrito( item, valorInput.value, 'plus' )">

                    <i class="fas fa-plus" style="color: white;"></i>

                </span>

                </div>

            </div>

            <div *ngIf="item.especificaciones.length === 0 && !storeService.abierto" class="d-grid gap-2">

                <!-- AGREGAR AL CARRITO  -->
                <button disabled class="btn bnt-sm p-1" type="button" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="font-weight: 600; color: white; font-size: 14px;"><i class="fas fa-shopping-cart" style="font-size: 12px;" ></i> Agregar </button>

            </div>

            <div *ngIf="item.especificaciones.length > 0 && storeService.abierto" class="d-grid gap-2">

                <button [disabled]="!item.estado || item.stock == 0" (click)="modalProducto(item)" class="btn bnt-sm p-1" type="button" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="font-weight: 600; color: white; font-size: 14px;"> Elegir opciones</button>

            </div>

            <div *ngIf="item.especificaciones.length > 0 && !storeService.abierto" class="d-grid gap-2">

                <button disabled class="btn bnt-sm p-1" type="button" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="font-weight: 600; color: white; font-size: 14px;"> Elegir opciones</button>

            </div>


            </div>

        </div>

        {{ lastIndex ? callback(item): ""}}

    </div>

</div>

<app-modal-product [childData]="infoProduct"></app-modal-product>