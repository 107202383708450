import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'value'
})
export class ValuePipe implements PipeTransform {

  transform(value: Object ): any {

    // console.log( value )

    // const valor = Object.values( value );

    return value;

  }

}
