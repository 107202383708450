import { Component, Input, SimpleChanges } from '@angular/core';
import { ProductService } from '../../../services/product/product.service';
import { StoreService } from '../../../services/store/store.service';
import { CarritoService } from '../../../services/carrito/carrito.service';
import { functions } from '../../../helpers/funcionts';
import { CookieService } from 'ngx-cookie-service';

declare var $:any;

@Component({
  selector: 'app-modal-product',
  templateUrl: './modal-product.component.html',
  styleUrls: ['./modal-product.component.css']
})
export class ModalProductComponent {

  @Input() childData: any;

  dataProduct:any[] = [];
  opcNoSelect:any[] = [];

  constructor( public storeService: StoreService, public CarritoService: CarritoService, private cookie: CookieService) {}

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    
    if( this.childData != undefined ){

      this.dataProduct = [];

      this.childData.map( ( a:any, i:any )=>{
  
        a.especificaciones.map( (item:any, index:any) => {

          this.opcNoSelect[index] = Object.keys( item ).toString();
  
          if( Object.values( item ).toString() == '[object Object]' ){
  
            Object.values( item ).map( (val:any)=>{
  
              val.map( (valor:any)=>{

                if( !valor.val ){

                   //ESPECIFICACIONES CON PRECIO
                  Object.keys( valor ).map( (v, i)=>{
    
                    let pro = v;
                    let va =  Object.values( valor )[i];
    
                    val[i] = { pre: pro, valor: va, act:false };
    
                  })

                }
               
              })
  
            })
  
          }else{

            //ESPECIFICACIONES SIN PRECIO
            Object.values( item ).map( (val:any, index:any)=>{
  
              val.map( (valores:any, i:any)=>{

                if( valores.val == undefined && valores.valor == undefined ){

                  val[i] = { val: valores, act:false };

                }
  
              })
  
            })
  
          }
          
        });
  
      })

      const existCokkie = this.cookie.check(`carrito-${this.childData[0].tienda}`);
     
      let carrito = [];
      if( localStorage.getItem(`carrito-${this.childData[0].tienda}`) != undefined ){
        carrito = JSON.parse( localStorage.getItem(`carrito-${this.childData[0].tienda}`)! );
      }

      const validar = carrito?.some( item=> item.id === this.childData[0].id );

      if(validar){
        this.dataProduct = [{
          ...this.childData[0],
          activar: true
        }];
      }else{
        //si no existe pasar activar en false
        this.dataProduct = [{
          ...this.childData[0],
          activar: false
        }];
      }

      setTimeout( ()=>{

        if( localStorage.getItem(`carrito-${this.childData[0].tienda}`) != undefined){
          carrito.forEach( (element1:any) => {
            if( this.childData[0].id === element1.id ){
              $(`.valorCarrito-${element1.id}`).val( element1.unit )
  
            }
      
          })

        }

      }, 100)
  
    }

  }
  
  //AGREGAR AL CATRRITO
  aggCar( data:any, tipo:boolean, valor:any, indi:any, dataProd:any ){
  
    //CUANDO VARIA EL PRECIO LAS OPCIONES

    if( tipo ){

      if (dataProd.oferta1 != "") {

        let today = new Date();
        let offerDate = new Date(

          parseInt(JSON.parse(dataProd.oferta1)[2].split("-")[0]),
          parseInt(JSON.parse(dataProd.oferta1)[2].split("-")[1]) - 1,
          parseInt(JSON.parse(dataProd.oferta1)[2].split("-")[2])

        )

        if (today < offerDate) {

          //SI LA OFERTA ES FIJA
          if( JSON.parse(dataProd.oferta1)[0] == "Fixed" ){

            console.log( 'por aqui' )

            //Descuento aplicado con la diferencia de precio fijo
            let value1 = JSON.parse(dataProd.oferta1)[1] * 100 / dataProd.precio;

            //Resta de 100% al % que se aplico al precio principal
            let price = Math.abs(100 - value1);

            //Precio final del descuento
            let offer = (data.valor - (data.valor * price / 100)).toFixed(2)

            let cambioPrecio = {
              oferta: dataProd.oferta1, 
              precio: dataProd.precio, 
              stock: dataProd.stock,  
              estado: dataProd.estado,
              fecha_create: dataProd.fecha_create
            }
      
            //CAMBIO DE PRECIO AL DAR CLICK
            dataProd.oferta = [functions.DinamicPrice( cambioPrecio )[0], offer, data.valor]
          
          }else{

            let cambioPrecio = {
              oferta: dataProd.oferta1, 
              precio: data.valor, 
              stock: dataProd.stock,  
              estado: dataProd.estado,
              fecha_create: dataProd.fecha_create
            }
      
            //CAMBIO DE PRECIO AL DAR CLICK
            dataProd.oferta = functions.DinamicPrice( cambioPrecio )

          }

        }else{

          let cambioPrecio = {
            oferta: dataProd.oferta1, 
            precio: data.valor, 
            stock: dataProd.stock,  
            estado: dataProd.estado,
            fecha_create: dataProd.fecha_create
          }

          //CAMBIO DE PRECIO AL DAR CLICK
          dataProd.oferta = functions.DinamicPrice( cambioPrecio )

        }

      }else{

        let cambioPrecio = {
          oferta: dataProd.oferta1, 
          precio: data.valor, 
          stock: dataProd.stock,  
          estado: dataProd.estado,
          fecha_create: dataProd.fecha_create
        }

        //CAMBIO DE PRECIO AL DAR CLICK
        dataProd.oferta = functions.DinamicPrice( cambioPrecio )

      }
     
      //DESMARCO LAS OPCIONES YA SELECCIONADAS
      this.opcNoSelect[indi] = Object.keys( valor[indi] ).toString()

      Object.values(valor[indi]).forEach( (a:any)=>{

        a.map( (b:any)=>{

          b.act = false;

        })

      })
      //SE ACTIVA AL QUE LE DOY CLICK DE LA OPCION
      data.act = true

      //SE OCULATA el boton PARA AGREAR AL CARRITO
      dataProd.activar = false;
      dataProd.cantidad = 1;
  
    }else{
      //DESMARCO LAS OPCIONES YA SELECCIONADAS
      this.opcNoSelect[indi] = Object.keys( valor[indi] ).toString()

      Object.values(valor[indi]).forEach( (a:any)=>{

        a.map( (b:any)=>{

          b.act = false;

        })

      })
      //SE ACTIVA AL QUE LE DOY CLICK DE LA OPCION
      data.act = true
      
      //SE OCULATA el boton PARA AGREAR AL CARRITO
      dataProd.activar = false;
      dataProd.cantidad = 1;
  
    }
  
  }

  //CAMBIO DE IMAGEN
  changeImg( valor:any, index:any, data:any ){

    $("#change-img").attr("src", `https://www.whtscatalogo.com/assets/img/products/${ this.dataProduct[0].tienda }/${valor}`);

    data.forEach((element:any, indi:any) => {
      
      $(`.img-select-${indi}`).css({ 'border':'2px solid rgb(221, 221, 221)' })


    });

    $(`.img-select-${index}`).css({ 'border':'4px solid rgb(158, 158, 158)' })

  }

  aggCarrito( data:any, cantidad:any, type:any ){

    let opcionesProd:any[] = [];

    this.opcNoSelect = [];

    //RECORRO LAS ESPECIFICACIONES PARA AGREGAR LOS DATOS SELECCIONADOS AL CARRITO
    data.especificaciones.forEach((element:any, index:any) => {

      let propiedad = Object.keys( element ).toString();

      Object.values( element ).forEach( ( element1:any )=>{

        element1.forEach((element2:any) => {
          //PREGUNTO SI SELECCIONE UNA y AGGREGO AL ARRAY DEL CARRITO
          if( element2.act ){

            if( element2.pre ){

              opcionesProd.push({ type: propiedad, valor: element2.pre })

            }else{

              opcionesProd.push({ type: propiedad, valor: element2.val })

            }

          }

        });

      })

    });

    //PREGUNTO SI la longitud del carrito es menor a las opciones DE LA DATA
    if( opcionesProd.length < data.especificaciones.length ){

      data.especificaciones.forEach((element:any, index:any) => {

        opcionesProd.forEach( (val:any)=>{

          if( val.type === Object.keys( element ).toString()){

            //LE ASIGNAMOS EL TYPE QUE SERIA LA PROPIEDAD DE LA ESPECIFICACION
            this.opcNoSelect[index] = val.type;

          }

        })

      })

    }else{
      //SI ES IGUAL LA LONGITUD SIGNIFICA QUE YA SELECIUONARON TODAS LAS OPCIONES
      data.especificaciones.forEach((element:any, index:any) => {

        this.opcNoSelect[index] = Object.keys( element ).toString();

      })

      //VALORES PARA AGREGAR AL CARRITO

      if( data.stock == 0 || !data.estado )return

      let numero = Number( cantidad );

      if( type != 'clear' ){

        if( type == 'plus' ){
  
          if( data.stock > numero ) numero++;  

          $(`.valorCarrito-${data.id}`).val( numero )
  
        }else if( type == 'minus'){
  
          numero--;
          $(`.valorCarrito-${data.id}`).val( numero )
  
        }else if( type == 'change'){

          if( Number(cantidad) <= 0 || cantidad == ''){ 
    
            numero = 1;
            $(`.valorCarrito-${data.id}`).val( numero )
            return;
          }
  
          if( cantidad <= data.stock ){
  
            numero = Number( cantidad )
            $(`.valorCarrito-${data.id}`).val( numero )
  
          }else{
  
            numero = data.stock;
            $(`.valorCarrito-${data.id}`).val( numero )
  
          }
  
        }
  
      }else{
        // return;
        //AQUI SE ELIMINA EL PRODUCTO DEL CARRITO
        data.activar = false;
        this.CarritoService.removeCaritot( 
          { ...data,
            id: data.id,
            detalles: JSON.stringify(opcionesProd),
            detalles1: opcionesProd, 
            store: data.tienda 
          } 
        )
  
        return;
      }

      //SE MUESTRA EL INPUT PARA AGREGAR AL CARRITO
      data.activar = true;

      //AGREGAR DATOS AL CARRITO
      let valor = {
        id: data.id,
        detalles: JSON.stringify(opcionesProd),
        detalles1: opcionesProd,
        image: data.imagen,
        name: data.nombre,
        precio: data.oferta,
        newPrecio: data.oferta[1],
        store: data.tienda,
        unit: numero,
        url: data.url,
        stock: data.stock,
        costo: data.costo
      }

      this.CarritoService.aggCarrito( valor )

      // console.log( opcionesProd )

    }

  }


}
