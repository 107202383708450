import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainPageComponent } from './main-page/main-page.component'; 
import { Error404Component } from './main-page/error404/error404.component';

const routes: Routes = [

    { path:'', component: MainPageComponent,
      children: [
        { path:'', loadChildren: ()=> import('./main-page/inicio/inicio.module').then( m=>m.InicioModule ) },
        { path:'', loadChildren: ()=> import('./main-page/categorias/categorias.module').then( m=>m.CategoriasModule ) },
        { path:'', loadChildren: ()=> import('./main-page/search/search.module').then( m=>m.SearchModule ) },
        { path:'', loadChildren: ()=> import('./main-page/pedidos/pedidos.module').then( m=>m.PedidosModule) },
        { path:'', loadChildren: ()=> import('./main-page/info-product/info-product.module').then( m=>m.InfoProductModule ) },
        { path:'', loadChildren: ()=> import('./main-page/ofertas/ofertas.module').then( m=>m.OfertasModule ) },
        { path:'**', component: Error404Component }
      ]
    }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }