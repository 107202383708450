import { Component, OnInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'whatscatalogo';

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    $.scrollUp({
      scrollText: '',
      scrollSpeed: 700
   })

   //Todo generar copos de nieve
  //  $(document).ready( function(){
  //   $.fn.snow({
  //     flakeColor: '#FFFFFF',
  //     minSize: 8, 
  //     maxSize: 15,
  //     newOn: 300
  //    });
  //   });

  }

}
