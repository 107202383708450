import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { functions } from '../../helpers/funcionts';
import { Productin } from '../../interface/product.inerface';
import { Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  dataImg:any[] = [];

  dataProduct:any[] = [];

  ofertas:boolean = false;

  productOfert:Subject<any> = new Subject<any>();

  ofertProducts:any[] = [];

  constructor( private http: HttpClient,  private router: Router  ) { this.allProduct().subscribe( (resp)=> {  } ) }

  getFilterData(orderBy:string, equalTo:string){

		return this.http.get(`${environment.Api}productos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&print=pretty`);

  }

  getLimitFilter(orderBy:string, equalTo:string, limit:number){

		return this.http.get(`${environment.Api}productos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&limitToLast=${limit}&print=pretty`);

  }

  allProduct(){

    return this.http.get(`${environment.Api}productos.json?orderBy="tienda"&equalTo="${this.router.url.split('/')[1]}"&print=pretty`)
    .pipe(

      map( (resp:any)=>{

        this.dataProduct = [];

        Object.keys( resp ).map( (a:any)=>{
          this.dataProduct.push({
            id:a,
            categoria: resp[a].categoria,
            descripcion: resp[a].descripcion, 
            detalles: resp[a].detalles,
            especificaciones: resp[a].especificaciones != '' ? JSON.parse(  resp[a].especificaciones ) : [],
            estado: resp[a].estado,
            fecha_create: resp[a].fecha_create,
            galeria: resp[a].galeria != '' ? JSON.parse(resp[a].galeria) : [],
            horizontal_banner: resp[a].horizontal_banner,
            imagen: resp[a].imagen == '' ? 'https://safetyaustraliagroup.com.au/wp-content/uploads/2019/05/image-not-found.png' : `${ environment.Server}assets/img/products/${resp[a].tienda}/${resp[a].imagen}`,
            mostrarOcultar: resp[a].mostrarOcultar,
            nombre: resp[a].nombre,
            oferta: functions.DinamicPrice(resp[a]),
            oferta1: resp[a].oferta,
            precio: resp[a].precio,
            prox: resp[a].prox,
            sales: resp[a].sales,
            stock: resp[a].stock,
            summary: resp[a].summary,
            tags: resp[a].tags,
            tienda: resp[a].tienda,
            url: resp[a].url,
            video:  resp[a].video,
            views: resp[a].views,
            activar: false,
            cantidad: 1,
            costo: resp[a].costo
          })
          
          
          if( resp[a].oferta != ''){

            let today = new Date();
    
            let offerDate = new Date(

              parseInt( JSON.parse(resp[a].oferta )[2].split("-")[0]),
              parseInt( JSON.parse(resp[a].oferta )[2].split("-")[1])-1,
              parseInt( JSON.parse(resp[a].oferta )[2].split("-")[2])

            )

            if( today < offerDate ){
              this.ofertProducts.push(resp[a])
              this.ofertas = true;
              
            }

          }

        })

        this.productOfert.next(this.ofertProducts)
        return resp;
      })

    )

  }

  getFilterDataLimitPedido(orderBy:string, equalTo:string, limit:number){

		return this.http.get(`${environment.Api}pedidos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&limitToLast=${limit}&print=pretty`);

  }

  registroPedidoSinUid(body:object ){

    return this.http.post(`${environment.Api}pedidos.json`, body);

  }

  getFilterDataPedidos(orderBy:string, equalTo:string){

		return this.http.get(`${environment.Api}pedidos.json?orderBy="${orderBy}"&equalTo="${equalTo}"&print=pretty`);

  }

  /*=============================================
  TRAER EL PRODUCTO POR SU ID
	=============================================*/
  getItemId(id: string) {

		return this.http.get(`${environment.Api}productos/${id}.json`);
		
	}

}
