<!-- Modal -->
<div class="modal fade" id="modalProduct" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">

    <div  class="modal-dialog">
  
      <div class="modal-content">
   
        <div *ngIf="dataProduct[0] != undefined" class="card" >
  
           <!-- BOTON PARA CERRAR EL MODAL  -->
          <button type="button" class="btn btn-close-modal" data-bs-dismiss="modal" style="background-color: #ededed;
          margin-left: auto;
          position: absolute;
          top: 12px;right: 12px;">
  
            <i class="fas fa-times fa-lg"></i>  
  
          </button>
  
          <!-- IMAGEN DEL MODAL  -->
          <img [src]="dataProduct[0].imagen" class="card-img-top" id="change-img" alt="..." loading=lazy>
  
          <!-- AVISO DE LA OFERTA DEL MODAL  -->
          <div class="offert-modal">

            <span *ngIf="dataProduct[0].oferta[0] != 'No disponible' && dataProduct[0].oferta[0] != 'Nuevo' && dataProduct[0].oferta[0] != 'Agotado' && dataProduct[0].oferta[0] != undefined" class="caja-offert" style="background-color: #ff1a1a;">{{ dataProduct[0].oferta[0] }}</span>
            <span class="caja-offert" *ngIf="dataProduct[0].oferta[0] == 'Nuevo' " style="background-color: #ffb700; ">{{ dataProduct[0].oferta[0] }}</span>
            <span class="caja-offert" *ngIf="dataProduct[0].oferta[0] == 'Agotado'" style="background-color: #000; ">{{ dataProduct[0].oferta[0] }}</span>

            <span class="caja-offert" *ngIf="dataProduct[0].oferta[0] == 'No disponible'" style="background-color: #000; ">{{dataProduct[0].oferta[0]  }}</span>

          </div> 
    
  
          <div class="card-body">
  
            <!-- GALLERY   -->
            <div class="gallery-card">
  
              <div class="caja-img" *ngFor="let item of dataProduct[0].galeria; let i = index" (click)="changeImg( item, i, dataProduct[0].galeria )">
  
                <img src="https://www.whtscatalogo.com/assets/img/products/{{ dataProduct[0].tienda }}/{{item}}" class="img-thumbnail img-select-{{i}}" alt="..." style="width: 80px; height: 80px; padding:0px; border: 0px; border-radius: 8px; border: 2px solid rgb(221, 221, 221); object-fit: contain;" loading=lazy>
  
              </div> 
   
            
            </div>
  
            <h4 class="card-title text-sm" >{{ dataProduct[0].nombre | titlecase }}</h4>
  
            <div class="card-text text-muted" style="font-size: 15px; font-weight: 400; line-height: 1rem;" [innerHTML]="dataProduct[0].descripcion"></div>

            <!-- PRECIOS  -->

            <div class="mt-2">

              <label style="font-size: 16px; font-weight: 600;"> Precio: </label> &nbsp;

              <span *ngIf="dataProduct[0].oferta[1] != undefined && dataProduct[0].oferta[2] == undefined" class="mb-0" [ngStyle]="{'color': storeService.allDataStore[0].color}" style="font-weight: 500; font-size: 16px;">{{ dataProduct[0].oferta[1] | currencyPrecio }}</span> &nbsp;

              <span *ngIf="dataProduct[0].oferta[1] != undefined && dataProduct[0].oferta[2] != undefined" class="mb-0" [ngStyle]="{'color': storeService.allDataStore[0].color}" style="font-weight: 500; font-size: 16px;">{{ dataProduct[0].oferta[1] | currencyPrecio }}</span> &nbsp;

              <span *ngIf="dataProduct[0].oferta[1] != undefined && dataProduct[0].oferta[2] != undefined"  class="mb-0 text-muted" style="font-weight: 300; font-size: 16px; text-decoration: line-through;">{{dataProduct[0].oferta[2] | currencyPrecio }}</span>


            </div>
  
            <!-- DETALLES DEL PRODUCTO  -->
  
            <div *ngFor="let item of dataProduct[0].especificaciones; let indi = index" class="mt-2">
  
              <span *ngIf="opcNoSelect[indi] == (item | key)" style="font-size: .90rem;  font-weight: 600;"> 
                 Selecciona, {{ item | key }}: 
              </span> 

              <span *ngIf="opcNoSelect[indi] == undefined" style="font-size: .90rem; font-weight: 600;" class="text-danger"> 
                <i class="fas fa-exclamation-circle"></i> Selecciona, {{ item | key }}: 
              </span>
  
              <div class="d-flex mt-3" style="overflow-x: auto;">

                <div *ngFor="let prod of item[ item | key ]; let i = index">

                  <div *ngIf="(prod.val | slices ) == ''">

                    <!-- background: #bbb; -->

                    <div (click)="aggCar( prod, true, dataProduct[0].especificaciones, indi, dataProduct[0] )" class="p-2" [ngClass]="{'activate-espc': prod.act, 'text-white':prod.act}" style="border: 1px solid #bbb; cursor:pointer;  color: rgb(0, 0, 0); border-radius: 8px;  margin-right: 8px;">
    
                      <span style="font-weight: 400;"> {{ prod.pre }}</span>
    
                    </div>

                  </div> 

                  <div *ngIf="(prod.val | slices ) != ''">

                    <div (click)="aggCar( prod, false, dataProduct[0].especificaciones, indi, dataProduct[0])"class="p-2" [ngClass]="{'activate-espc': prod.act, 'text-white':prod.act}" style="border: 1px solid #bbb; cursor:pointer; color: #000; border-radius: 8px;  margin-right: 8px;">
    
                      <span style="font-weight: 400;"> {{ prod.val  }}</span>
    
                    </div>

                  </div>

                </div>
  
              </div> 
         
            </div> 
          
          </div>
          
          <div class="card-footer" style="background: white; border-top: 0px; padding: 0.75rem; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
  
            <div *ngIf="storeService.abierto" class="d-grid gap-2">

              <button [disabled]="!dataProduct[0].estado || dataProduct[0].stock == 0" *ngIf="!dataProduct[0].activar" (click)="aggCarrito( dataProduct[0], 1, 'agg' )" class="btn bnt-sm p-1" type="button" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="font-weight: 600; color: white; font-size: 17px;"><i class="fas fa-shopping-cart" style="font-size: 12px;"></i> Agregar</button>

              <div *ngIf="dataProduct[0].activar"  class="input-group" >
            
                <span *ngIf="(valorInput.value | value ) > 1" (click)="aggCarrito( dataProduct[0], valorInput.value, 'minus' )" class="input-group-text" style="background:white; padding: 9px;" >
                  <i class="fas fa-minus"></i>
                </span>

                <span *ngIf="(valorInput.value | value ) <= 1 || ( valorInput.value | value ) == ''" (click)="aggCarrito( dataProduct[0], 0, 'clear' )" class="input-group-text" style="background:white; font-size: 14px; padding: 9px;" >
                  <i class="far fa-trash-alt" style="color: #fc4545;"></i>
                </span>

                <input type="number" class="form-control text-center valorCarrito-{{ dataProduct[0].id }}" value="1" min="1" [max]="dataProduct[0].stock" #valorInput style="font-size: 14px;" (keyup)="aggCarrito( dataProduct[0], valorInput.value, 'change' )">

                <span class="input-group-text" style="background: linear-gradient(
                  89.96deg,#24d683 1.05%,#1dc778 17.07%,#00af71 99.96%); font-size: 14px; padding: 9px;" (click)="aggCarrito( dataProduct[0], valorInput.value, 'plus' )">

                  <i class="fas fa-plus" style="color: white;"></i>

                </span>

              </div>

            </div>

            <div *ngIf="!storeService.abierto" class="d-grid gap-2">

              <button disabled class="btn bnt-sm p-1" type="button" [ngStyle]="{'background': storeService.allDataStore[0].color}" style="font-weight: 600; color: white; font-size: 17px;"><i class="fas fa-shopping-cart" style="font-size: 12px;"></i> Agregar</button>

            </div>
  
          </div>
  
        </div>
  
      </div>
  
    </div>
  
  </div>
  