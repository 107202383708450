import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  mapBox = ( mapboxgl as typeof mapboxgl )
  map: mapboxgl.Map | undefined;

  style:string = 'mapbox://styles/mapbox/streets-v11';
  lat:number= 7.06851;
  lng:number= -66.54767;
  zoom = 4;
  markerMove:any = null;

  constructor() {
    //SE LE ASIGNA EL TOKEN AL MAPA
    this.mapBox.accessToken = environment.mapbox;

  }

  buildMpa( type ): Promise<any> {

    if( type ){
      this.lat= -35.9968222;
      this.lng = -65.0536526;
    }

    //SE CONSTRUYE EL MAPA
    return new Promise( ( resolve, reject )=>{

      try{

        this.map = new mapboxgl.Map({
          container:'map',
          style: this.style,
          zoom: this.zoom,
          center: [ this.lng, this.lat ]
        })

        const marker = new mapboxgl.Marker()

        // this.map.addControl( new mapboxgl.NavigationControl )

        //AQUI SE CONSTRUYE EL INPUT BUSCADOR DE DIRECCIONES
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl
        });

        //ESCUCAHR UN EVENTO DEL GEOCOER
        geocoder.on( 'result', ($event)=>{

          const { result } = $event;
          geocoder.clear();

          // console.log( $event )

        })

        resolve( {
          map: this.map,
          geocoder,
          marker
        })

      }catch( e ){

        reject(e)

      }

    })

  }

  //AGREGAR MARCADOR PERSONALIZADO
  // addMarkerCustom( coords ) : void {

  //   const el = document.createElement( 'div' );
  //   el.className = 'marker';
  //   this.markerMove = new mapboxgl.Marker(el);
  //   this.markerMove.setLngLat( coords )
  //     .addTo( this.map )

  // }

}
