import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductosComponent } from './productos.component';
import { PipesModule } from '../../../pipes/pipes.module';
import { DirectiveModule } from '../../../directive/directive.module';
import { ModalProductModule } from '../modal-product/modal-product.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    ProductosComponent
  ],
  exports: [
    ProductosComponent
  ],
  imports: [
    CommonModule,
    ModalProductModule,
    DirectiveModule,
    PipesModule,
    RouterModule
  ]
})
export class ProductosModule { }
