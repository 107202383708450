import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-oferta',
  templateUrl: './modal-oferta.component.html',
  styleUrls: ['./modal-oferta.component.css']
})
export class ModalOfertaComponent implements OnInit {

  @Input() ofertas = [];

  constructor(
    public activeModal: NgbActiveModal,
    public router:Router
  ) { }

  ngOnInit(): void {}

  ofertafnc(){
    console.log('--')
    this.router.navigate([this.router.url.split('/')[1],'o','ofertas'])
  }

}
