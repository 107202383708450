import { Directive } from '@angular/core';

@Directive({
  selector: '[appDeliveryg]'
})
export class DeliverygDirective {

  constructor(  ) { }

}
