export class functions {

    /*=============================================
    OFERTA
    =============================================*/

    static DinamicPrice(response:any):any {

        let type;
        let value;
        let offer;
        let precio;
        let disccount;
        let arrayPrice = [];
        let offerDate;
        let today = new Date();
        let oferta;


        if (response.oferta != "") {

            offerDate = new Date(

                parseInt(JSON.parse(response.oferta)[2].split("-")[0]),
                parseInt(JSON.parse(response.oferta)[2].split("-")[1]) - 1,
                parseInt(JSON.parse(response.oferta)[2].split("-")[2])

            )

            if (today < offerDate) {

                type = JSON.parse(response.oferta)[0];
                value = JSON.parse(response.oferta)[1];

                if (type == "Disccount") {

                    offer = (response.precio - (response.precio * value / 100)).toFixed(2)
                }

                if (type == "Fixed") {

                    let resta = Math.abs(response.precio - value);
                    
                    offer = value;
                    value = ( (resta / value ) * 100 ).toFixed(2);

                }

                disccount = `-${value}%`;

                precio = offer;

                oferta = response.precio.toFixed(2);

            } else {


                precio = response.precio.toFixed(2);
            }

        } else {

            precio = response.precio.toFixed(2);
        }

        /*=============================================
        Definimos si el producto tiene stock
        =============================================*/

        if (response.stock == 0) {

            disccount = `Agotado`;

        }

        /*=============================================
        Definimos si el producto esta desactivado
        =============================================*/

        if ( !response.estado ) {

            disccount = `No disponible`;

        }

        /*=============================================
        Definimos si el producto es nuevo
        =============================================*/
        if (response.fecha_create != '') {

            //Día actual
            let today1 = new Date().getTime();

            let fecha_creacion = new Date(response.fecha_create);

            //le sumo 5 dias en milisegundos
            let cino_dias = fecha_creacion.getTime() + 432000000;

            //comparo en milisegundos si ya transcurrieron los 5
            if (cino_dias > today1) {
                // #ffb700
                disccount = 'Nuevo';

            }

        }


        arrayPrice[0] = disccount;
        arrayPrice[1] = Number(precio);
        arrayPrice[2] = oferta;

        return arrayPrice;
        

    }

  /*=============================================
	 CORRELACION DE LOS PEDIDOS
	=============================================*/
	static nCorrelacion( cod:any, type = '' ):any{

		let codigo;
		let codigoFactura;

		if( Number(cod) == 99999 ){

			codigo = 1;

		}else{

      if( type == 'new' ){

        codigo = 1;

      }else{
        codigo = Number(cod) + 1;

      }

		}

    if( codigo > 9 && codigo.toString().length == 2 ){

      codigoFactura = `0000${codigo}`;

    }else if(codigo <= 9 && codigo.toString().length == 1){

      codigoFactura = `00000${codigo}`;

    }else if( codigo > 99 && codigo.toString().length == 3){

      codigoFactura = '000'+codigo;

    }else if( codigo <= 99 && codigo.toString().length == 2){

      codigoFactura = '0000'+codigo;

    }else if( codigo > 999 && codigo.toString().length == 4){

      codigoFactura = '00'+codigo;

    }else if(codigo <= 999 && codigo.toString().length == 3){

      codigoFactura = '000'+codigo;

    }else if( codigo > 9999 && codigo.toString().length == 5){

      codigoFactura = '0'+codigo;

    }else if( codigo <= 9999 && codigo.toString().length == 4 ){

      codigoFactura = '00'+codigo;

    }else if(codigo > 99999 && codigo.toString().length == 6 ){

      codigoFactura = codigo;

    }
		return codigoFactura;

	}

  /*=============================================
	TOTALES CON VARIAS MONEDAS
	=============================================*/
  static currencyAll( total:any, delivery:any, descount:any, monedas:any ){

    let totalvariasCrurrency = '';

    if( monedas.monedaPrincipal == 'USD'  ){

      monedas.monerda.forEach(element => {

        if( element.value != 0 ){

          let total1:any = ( (total + delivery )*element.value ).toFixed(2)

          if( descount != 0 ){
  
            totalvariasCrurrency += `%0d%0a*${ element.symbol }:*%20${ ( total1 - (total1 * descount) / 100).toFixed(2) }`;
  
          }else{

            totalvariasCrurrency += `%0d%0a*${ element.symbol }:*%20${ total1 }`;

          }

        }
        
        
      });

    }else if( monedas.monedaPrincipal == 'COP'){

      monedas.monerda.forEach(element => {

        if( element.value != 0 ){

          let total1:any = ( (total + delivery ) / element.value ).toFixed(2)

          if( descount != 0 ){
  
            totalvariasCrurrency += `%0d%0a*${ element.symbol }:*%20${ ( total1 - (total1 * descount) / 100).toFixed(2) }`;
  
          }else{

            totalvariasCrurrency += `%0d%0a*${ element.symbol }:*%20${ total1 }`;

          }

        }
        
      });

    }else{

      let total1:any = (total + delivery ).toFixed(2)

      if( descount != 0 ){

        totalvariasCrurrency += `%0d%0a*Total:*%20$${ ( total1 - (total1 * descount) / 100).toFixed(2) }`;

      }else{

        totalvariasCrurrency += `%0d%0a*Total:*%20$${ total1 }`;

      }
    }

    return totalvariasCrurrency;

  }

}