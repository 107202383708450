import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mostrarDelibe'
})
export class MostrarDelibePipe implements PipeTransform {

  transform(value: any ): unknown {

    console.log( value )

    return null;
  }

}
