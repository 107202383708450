import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appImgRota]'
})
export class ImgRotaDirective {

  constructor( private ElementRef: ElementRef ) { }

  @HostListener('error')
  onError(){

    this.ElementRef.nativeElement.src = 'https://www.whtscatalogo.com/assets/img/products/default/banner-principal.jpg'


  }

}
