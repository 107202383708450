import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValuePipe } from './value.pipe';
import { KeyPipe } from './key.pipe';
import { SlicesPipe } from './slices.pipe';
import { CurrencyPrecioPipe } from './currency-precio.pipe';
import { MostrarDelibePipe } from './mostrar-delibe.pipe';
import { UrlvideoPipe } from './urlvideo.pipe';


@NgModule({
  declarations: [
    ValuePipe,
    KeyPipe,
    SlicesPipe,
    CurrencyPrecioPipe,
    MostrarDelibePipe,
    UrlvideoPipe
   ],
  exports:[
    ValuePipe,
    KeyPipe,
    SlicesPipe,
    CurrencyPrecioPipe,
    MostrarDelibePipe,
    UrlvideoPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
