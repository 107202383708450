<div class="card card-modal" style="border: 2px solid #06b473;">
    <div class="modal-header" style="border-bottom: 0px;">
      <h4 class="modal-title" id="modal-basic-title" style="position: relative;
      left: 36%;">Sucursales</h4>
      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()"></button> -->
    </div>
    <div class="modal-body">
      <div class="p-2">
        <div class="d-flex bd-highlight sucursal" *ngFor="let item of sucursales; let indi = index" [ngClass]="{'active': item?.active}" (click)="sucursal(item, indi)">
          <div class="p-1 flex-shrink-1 bd-highlight"> <h5> ✅ </h5></div>
          <div class="p-1 w-100 bd-highlight">
            <h5> {{item.sucursal | titlecase}}  </h5> 
          </div>
        </div>
      </div>
     
    </div>
    <div class="modal-footer" style="border-top: 0px; justify-content: center;">
      <button type="button" class="btn text-white" style="background:#06b473" (click)="activeModal.close(); continuar()" [disabled]="sucursalselect == ''">Continuar</button>
    </div>
</div>