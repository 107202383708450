import { Component, Input } from '@angular/core';
import { ProductService } from '../../../services/product/product.service';
import { StoreService } from '../../../services/store/store.service';
import { CarritoService } from '../../../services/carrito/carrito.service';
import { CookieService } from 'ngx-cookie-service';

declare var $:any;

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent {

  @Input() dataProduct: any;

  render:boolean = true;

  infoProduct:any;

  constructor( public producService: ProductService, public storeService: StoreService, private CarritoService: CarritoService, private cookie: CookieService) { }

  ngOnChanges(): void {

    this.render = true;

  }

  //AGREGAR DATOS AL CARRITO
  aggCarrito( data:any, cantidad:any, type:string ){

    // console.log( data )
    let numero = Number( cantidad );

    if( data.stock == 0 || !data.estado )return

    if( type != 'clear' ){

      if( type == 'plus' ){

        if( data.stock > numero ) numero++;  

        $(`.valorCarrito-${data.id}`).val( numero )

      }else if( type == 'minus'){

        numero--;

        $(`.valorCarrito-${data.id}`).val( numero ) 

      }else if( type == 'change'){

        if( Number(cantidad) <= 0 || cantidad == ''){ 
    
          numero = 1;

          $(`.valorCarrito-${data.id}`).val( numero )
          return;
        }

        if( cantidad <= data.stock ){

          numero = Number( cantidad )

          $(`.valorCarrito-${data.id}`).val( numero )
           
        }else{

          numero = data.stock;

          $(`.valorCarrito-${data.id}`).val( numero )

        }

      }

    }else{

      //AQUI SE ELIMINA EL PRODUCTO DEL CARRITO
      data.activar = false;
      this.CarritoService.removeCaritot( { ...data, id:data.id, store: data.tienda } )

      return;
    }

    data.activar = true;

    let valor = {
      id: data.id,
      detalles: "[]",
      detalles1: [],
      image: data.imagen,
      name: data.nombre,
      precio: data.oferta,
      newPrecio: data.oferta[1],
      store: data.tienda,
      unit: numero,
      url: data.url,
      stock: data.stock,
      costo: data.costo
    }
    console.log("🚀 ~ file: productos.component.ts:103 ~ ProductosComponent ~ aggCarrito ~ valor:", valor)

    this.CarritoService.aggCarrito( valor )

  }


  /*=============================================
  ABRIR MODAL DEL PRODUCTO
  ============================================*/
  modalProducto(data:any){

    this.infoProduct = [data];

    $('#modalProduct').modal('toggle')
   

  }

  /*=============================================
  CALLBACK DEL FOR DE LOS PRODUCTOS
  ============================================*/
  callback(data:any){

    if( this.render){

      // console.log( data.tienda )

      this.render = false;
  
      //MOSTRAMOS
      if( localStorage.getItem(`carrito-${data.tienda}`) != undefined ){

        const carrito = JSON.parse( localStorage.getItem(`carrito-${data.tienda}`)! );
        // const carrito = JSON.parse( this.cookie.get(`carrito-${data.tienda}`)! );

        setTimeout(()=>{  

          carrito.forEach( (element1:any) => {

            $(`.valorCarrito-${element1.id}`).val( element1.unit )

          });
            
        }, 10)

      }

    }

  }

}
