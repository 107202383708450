<div id="intro" style="display: block;">

    <div class="caja-intro">
        <!-- appImgdirective [urlCustom]="dataStore[0]?.logo" -->
        <img  *ngIf="preload" [src]="dataStore[0]?.logo" alt="" loading="lazy" style=" width: 100%; -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%; 
        border: 5px solid rgb(239 239 239 / 50%);">

        <button class="btn mt-2" type="button" disabled style="border: 1.5px solid #47475a; ;background: #fff; color: #000; font-weight: 500; margin-left: 20px">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="color: #000;"></span>
            Cargando...
        </button>

        <div class="mb-4 mt-4"></div>
        <div class="mb-4 mt-4"></div>

        <p style="color: #5e666e; margin-left: 30px; "> © whatscatalogo </p>

    </div>

</div>


<!-- HEADER  -->
<header *ngIf="preload">

    <!-- BANNER PUBLICITARIO  -->
    <div class="p-2 text-white text-center" [ngStyle]="{'background': dataStore[0]?.color }"  style="width: 100%;">

        <a *ngIf="ProductService.ofertas" [routerLink]="[tienda,'o','ofertas']" style="text-decoration: none; color:white;">

            🎉 !Ofertas! Ver más 🎉

        </a>

    </div>

    <!-- IMAGEN BANNER DE LA TIENDA  -->

    <div class="banner">
        
        <img [src]="dataStore[0]?.banner" class="img-fluid" width="1120" height="283" alt="" loading="lazy">
        
    </div>

    <!--REDES SOCIALES  -->
    <div class="redes">

        <a *ngIf="dataStore[0]?.whatsapp" [href]="dataStore[0]?.mensajeWhatsapp" target="_blank" class="m-1" [ngStyle]="{'color': dataStore[0]?.color }">
            <i class="fab fa-whatsapp"></i>
        </a>

        <a *ngFor="let item of socialProperty; let i = index" [href]="socialValue[i]" target="_blank" class="m-1" [ngStyle]="{'color': dataStore[0]?.color }">
            <i class="fab fa-{{item}}"></i>
        </a>
       
    </div>

    <!-- LOGO  -->
    <div class="logo">
        <a [routerLink]="[dataStore[0]?.url]" >
            <img  [src]="dataStore[0]?.logo" alt="" loading="lazy">
        </a>

    </div>

    <!-- TITULO  -->
    <div  class="px-3 bg-white pb-2">

        <div class="titulo-descripcion">

            <h1 class="font-weight-bold h3"> {{ dataStore[0]?.tienda }} </h1>

            <div  class="font-weight-light text-dark">
                <p [innerHTML]="dataStore[0]?.descripcion"></p>
            </div>

            <div class="row">

                <div class="col-9" style="border-right: 1px solid #ececec; margin-top: 7px">
        
                    <h6 class="direccion">
                        <i class="fas fa-map-marker-alt" [ngStyle]="{'color': dataStore[0]?.color }"></i>
                        {{ dataStore[0]?.direccion }}
                    </h6>
        
                </div>

                <div class="col-3 mb-2">

                    <button *ngIf="dataStore[0]?.abierto" class="btn btn-sm text-white" style="background:#06b473"> Abierto </button>

                    <button *ngIf="!dataStore[0]?.abierto" class="btn btn-sm text-white" style="background:#ff3030"> Cerrado </button>

                </div>
        
            </div>
        
        </div>

    </div>

    <!-- BUSCADOR  -->
    <div class="px-3">

        <form autocomplete="off"
            [formGroup]="f"
            (ngSubmit)="search()">

            <div class="input-group mb-3 input-busqueda">

                <input type="text" class="form-control search" formControlName="busqueda" placeholder="¿Que estas buscando?" aria-label="Recipient's username" aria-describedby="basic-addon2" [ngClass]="{'is-invalid': invaldField('busqueda') }" pattern="[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]{1,50}" required>

                <button type="submit" class="input-group-text boton-search" [ngStyle]="{'background': dataStore[0]?.color }" id="basic-addon2" ><i class="fas fa-search text-white"></i></button>

                <!-- <span class="invalid-feedback" *ngIf="busqueda.invalid && busqueda.errors?.required">Por favor escribe tu busqueda</span> -->
                <span class="invalid-feedback" *ngIf="busqueda.invalid && busqueda.errors?.pattern">Por favor sin caracteres</span>

            </div>

        </form>
        
    </div>


</header>

<!-- MENU  -->
 <div style="border-top: 7px solid #f4f4f4;"></div>

<div class="d-flex py-3 menu">

    <a [routerLink]="[dataStore[0]?.url]" class="caja-menu p-2" >

        <span class="text-menu-list">Inicio</span>

        <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'background': dataStore[0]?.color }"></div>

    </a>

    <ng-container *ngFor="let item of categoriaService.dataCategoira" >

        <a [routerLink]="[item.tienda, item.url]" class="caja-menu p-2">

            <span class="text-menu-list">{{ item.icon }}  {{item.nombre}}</span>

            <div routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" [ngStyle]="{'background': dataStore[0]?.color }"></div>
        </a>

    </ng-container>

</div>


