import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-sucursal',
  templateUrl: './modal-sucursal.component.html',
  styleUrls: ['./modal-sucursal.component.css']
})
export class ModalSucursalComponent implements OnInit {

  @Input() sucursales = [];

  sucursalselect:any = '';

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  sucursal(item, indi){
    this.sucursalselect = item.telefono;
    this.sucursales= this.sucursales.map((item, id)=>({
      ...item,
      active: id == indi ? true : false
    }))

  }

  continuar(){
    localStorage.setItem('sucursal', this.sucursalselect)
  }

}
