import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Subscription } from 'rxjs';
import { StoreService } from '../services/store/store.service';

@Pipe({
  name: 'currencyPrecio',
  pure: false // PROPIEDAD PURE DE UN PIPE, VIENE EN TRU POR DEFECTO ESTO SIGNIFICA Q SE VA A EJECUTAR UNA UNICA VEZ Y LE CAMBIAMOS EL VALOR A FALSO SE VA A INICIALITAR VARIAS VECES
})
export class CurrencyPrecioPipe implements PipeTransform, OnDestroy  {

  public value:string = '';
  private listen$: Array<Subscription> = [];

  constructor( private StoreService: StoreService ){  }

  transform(valueIn: any ): any {

    const observer1$ = this.StoreService.currency$
      .subscribe( ( resp:any )=>{

        if( resp.principal == 'USD' ){

          this.value = `${ resp.symbol} ${( valueIn * resp.value ).toFixed(2)}`;

        }else{

          this.value = `${ resp.symbol} ${( valueIn / resp.value ).toFixed(2)}`;
        }

      })
      
    this.listen$ = [observer1$]

    return this.value;

  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.listen$.forEach( a=> a.unsubscribe())
  }

}
